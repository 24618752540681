import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';


import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import ProjectSelector from 'components/General/ProjectSelector';
import ModelList from 'components/Lists/ModelList';
import ModelGeneralPerformance from 'components/Dashboards/ModelGeneralPerformance';


import useFetchModelList from 'components/Hooks/useFetchModelList';
import ModelInformation from 'components/Graphs/ModelInformation';


const SelectModel = () => {
    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));
    const [project, setProject] = useState(null);
    const { models: modelList , updateModelState} = useFetchModelList(project?.id);

    // Internal States
    const [selectedModel, setSelectedModel] = useState(null);


    // On project change, update the selected model
    const handleNewProjectSelected = (project) => {
        setProject(project);
        setSelectedModel(null);
    }

    // Render the component
    return(
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 1. Header */}
            <FlexBetween>
                    <Header title="Select a model" subtitle="Select the assistant you want to use for your projects" />
                    <ProjectSelector handleNewProjectSelected={handleNewProjectSelected} />
            </FlexBetween>

            {/* 2. Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.1. Model Selection */}
                    <Box gridColumn="span 3" gridRow="span 3" color={theme.palette.secondary[1000]}>
                        <ModelList
                            modelList={modelList}
                            selectedModel={selectedModel}
                            setSelectedModel={setSelectedModel}
                            updateModelState={updateModelState}
                        />
                    </Box>

                    {/* 2.2 Plot Area */}
                    <Box gridColumn="span 9" gridRow="span 8" color={theme.palette.secondary[1000]}>
                        <ModelInformation selectedModel={selectedModel} />
                    </Box>

                    {/* 2.3. Label Area */}
                    <Box gridColumn="span 3" gridRow="span 5" color={theme.palette.secondary[1000]}>
                        <ModelGeneralPerformance
                            selectedModel={selectedModel}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SelectModel;