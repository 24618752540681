import { createTheme } from '@mui/material/styles';

// Create a custom theme
const formTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Color of the label when focused
                    //'& label.Mui-focused': { color: 'green'},

                    // Color of the underline when focused
                    //'& .MuiInput-underline:after': {borderBottomColor: 'green'},

                    // Default border color
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {borderColor: '#90caf9'},
                        '&:hover fieldset': {borderColor: '#1976d2'},
                        //'&.Mui-focused fieldset': {borderColor: 'green'},
                    },

                    // '& .Mui-error': {
                    //     marginBottom: "0rem", // Adjust the margin when there's an error
                    // },

                    // '& .MuiFormHelperText-root': {
                    //     position: 'absolute',
                    //     bottom: '-0px', // Adjust the positioning of the helper text
                    // },
                },
            },
        },
    },
});

export default formTheme;