import React, { useState, useEffect } from 'react';
import { Box, Paper, List, ListItem, ListItemText, IconButton, Typography, Avatar, ListItemAvatar, ListItemButton, useTheme } from '@mui/material';

import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const ModelList = ({ modelList, selectedModel, setSelectedModel, updateModelState }) => {
    // Get the theme
    const theme = useTheme();
    const [noModels, setNoModels] = useState(true);

    // Effect to update the noModels state
    useEffect(() => {
        if (modelList.length === 0) {
            setNoModels(true);

        } else {
            setNoModels(false);
        }
    }, [modelList]);


    // Handle the selection of a model
    const handleSetActive = async (modelId) => {
        // Get the model to update
        const model = modelList.find((model) => model.id === modelId);
        if (model) {
            await updateModelState(model);
        }
    };

    // Handle the selection of a model
    const handleSelectedModel = (model) => {
        setSelectedModel(model);
    };

    return (
        <Paper elevation={3} sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px'}}>

            {/* Section Title */}
            <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px 10px 0 0',
                        padding: '1rem',
                        gap: "1rem",
                        backgroundColor: theme.palette.secondary[50],
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <ListAltIcon sx={{ color: theme.palette.secondary[800]}} />
                    <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                        Models Trained
                    </Typography>
                </Paper>

            {/* Content */}
            {noModels ? (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            borderRadius: '10px',
                            padding: '1rem'
                            }}
                    >
                        <Typography variant="h6" color={theme.palette.secondary[800]}>
                            No models trained yet
                        </Typography>
                    </Box>
                </>
            ) : (
                <>
                    {/* List of models */}
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            borderRadius: '10px',
                            padding: '1rem'
                            }}
                    >
                        <List sx={{ width: '100%' }}>
                            {modelList.map((model) => (
                                <ListItem
                                    key={model.id}
                                    disablePadding
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => { e.stopPropagation(); handleSetActive(model.id); }}>
                                            <SettingsInputHdmiIcon />
                                        </IconButton>
                                    }
                                    sx={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                                >
                                    <ListItemButton
                                        onClick={() => handleSelectedModel(model)}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: selectedModel?.id === model.id ? 'action.selected' : '',
                                            '&:hover': {
                                                backgroundColor: selectedModel?.id === model.id ? 'action.selected' : 'action.hover',
                                            },
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {!model.active
                                                    ? <AutoFixHighIcon sx={{ color: theme.palette.failure[500] }} />
                                                    : <AutoFixHighIcon sx={{ color: theme.palette.success[500] }} />
                                                }
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText primary={model.modelName} primaryTypographyProps={{ noWrap: true }} sx={{ ml: 2 }}/>

                                    </ListItemButton>
                                </ListItem>
                            ))}
                            </List>
                    </Box>


                </>
            )}

        </Paper>
    );
}

export default ModelList;