import { useEffect, useState, useCallback } from 'react';

// From the API
import { useDispatch, useSelector } from "react-redux";
import { setRefreshToken } from "state";
import { useRefreshProfileTokenMutation } from 'state/api';
const defaultProfileImage = `/assets/profile.jpg`;

// Token selector
const selectUser = state => state.persistedReducer.user;
const selectToken = state => state.persistedReducer.token;

// Custom hook to refresh the access tokens for the images in a project
function useRefreshProfileToken() {

    // Variables
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const [refreshProfileToken] = useRefreshProfileTokenMutation();
    const [userProfilePicture, setUserProfilePicture] = useState(defaultProfileImage);


    // Callback to refresh the profile token
    const refreshProfile = useCallback(async () => {
        // If user is null, return
        if(!user) return;

        // Refresh the token
        let res = '';
        try{
            const response = await refreshProfileToken({ token, requestBody: { userEmail: user.email } }).unwrap();
            if(response) {
                res = response.url;
                dispatch(setRefreshToken({
                    accessToken: response.url,
                    expirationTime: response.expirationTime
                }));
                setUserProfilePicture(response.url);
            } else {
                console.error('Error refreshing profile token');
                setUserProfilePicture(defaultProfileImage);
            }
        } catch (error) {
            console.error('Error refreshing profile token');
            setUserProfilePicture(defaultProfileImage);
        }

        return res;

    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    // Refresh the token when the user changes
    useEffect(() => {
        if(!user) return;

        // Verify if the user has a profile picture
        if (user.profilePictureAccessToken !== '') {
            if (user.profilePictureExpireDate === '') {
                refreshProfile();
            } else {
                // Check the expiration date of the token
                const tokenDate = new Date(user.profilePictureExpireDate);
                const currentDate = new Date();

                // Check the diference
                const timeToExpire = Math.round((tokenDate - currentDate) / 60000);
                if (timeToExpire < 120) {
                    refreshProfile()
                } else {
                    setUserProfilePicture(user.profilePictureAccessToken);
                }
            }
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    return { userProfilePicture, refreshProfile };
}

export default useRefreshProfileToken;