import React, { useState } from 'react';
import { Box, Modal, Button, Typography, Paper, List, ListItem, ListItemText, ListItemIcon, LinearProgress, IconButton, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// API
import { useSelector } from "react-redux";
import { useUploadImageMutation } from 'state/api';

// Modal style
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Increase width
    maxWidth: '600px', // Set a max-width for larger screens
    height: 'auto', // Adjust height based on content
    maxHeight: '80vh', // Prevent modal from being too tall
    overflowY: 'auto', // Allow scrolling within the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const UploadImageModal = ({ projectId, ownerEmail, open, handleClose }) => {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadImage] = useUploadImageMutation();

    // Get the user and the token from the state
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp': ['.bmp'],
            'image/gif': ['.gif'],
            'image/webp': ['.webp'],
            'image/tiff': ['.tiff', '.tif']
        },

        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.map(file => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
                size: file.size,
                status: 'pending', // Initial status for new files
            }));
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        },
    });

    const removeFile = (fileName) => {
        // Filter out the file to remove
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        // Update uploadProgress state to remove the entry for the removed file
        setUploadProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            delete newProgress[fileName];
            return newProgress;
        });
    };

    const startUploads = async (index = 0) => {
        if (index < files.length) {
            // Step 1. Get the file to upload
            const file = files[index];

            // Step 2. Prepare the additional info to send with the file
            const additionalInfo = {
                projectId: projectId,
                ownerEmail: ownerEmail
            };

            // Step 3. Update the UI to indicate that the file is being uploaded
            setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'uploading'}));

            // Step 4. Try to upload the file
            try {
                // Step 4.1. Send the file to the server
                await uploadImage({ token, file: file.file, additionalInfo }).unwrap();

                // Step 4.2. On success, update the UI accordingly
                setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'success'}));

            // Step 5. Handle errors
            } catch (error) {
                setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'error'}));
            }

            // Step 6. Proceed to the next file
            startUploads(index + 1);
        }
    };

    const calculateOverallProgress = () => {
        const progressValues = Object.keys(uploadProgress).map(key => uploadProgress[key] === 'success' ? 1 : 0);
        const totalSuccess = progressValues.reduce((acc, cur) => acc + cur, 0);
        return files.length > 0 ? (totalSuccess / files.length) * 100 : 0;
    };

    return (
        <Modal
            open={open}
            onClose={() => { handleClose(); setFiles([]); setUploadProgress({}); }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...modalStyle,
                width: '80%', // Adjusted for wider modal
                maxWidth: '800px', // Increased max-width for larger screens
            }}>
                <Typography variant="h2" color={theme.palette.primary[900]} fontWeight="bold" sx={{ mb: "5px" }}>
                    Upload Images
                </Typography>

                {/* Subtitle */}
                <Typography variant="h5" color={theme.palette.primary[700]}>
                    Drag and drop images here to upload
                </Typography>


                <Paper {...getRootProps()} sx={{
                    mt: 2,
                    p: 2,
                    border: '2px dashed',
                    borderColor: isDragActive ? theme.palette.primary.main : '#eeeeee',
                    bgcolor: isDragActive ? theme.palette.action.hover : '#ffffff',
                    color: theme.palette.text.secondary,
                    textAlign: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '150px',
                }}>
                    <input {...getInputProps()} />
                    <Typography variant="h5">
                        {isDragActive ? 'Drop the files here ...' : 'Drag \'n\' drop some files here, or click to select files'}
                    </Typography>
                </Paper>
                <List sx={{ mt: 2, maxHeight: '300px', overflowY: 'auto' }}>
                    {files.map((file, index) => (
                        <ListItem
                            key={index}
                            sx={{ bgcolor: 'action.hover', mb: 1, borderRadius: '5px', display: 'flex', alignItems: 'center' }}
                        >
                            <ListItemIcon>
                                {file.file.type.startsWith('image/') ? <ImageIcon /> : <InsertDriveFileIcon />}
                            </ListItemIcon>
                            <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} KB`} />
                            {uploadProgress[file.name] === 'success' ? (
                                <CheckCircleIcon color="success" />
                            ) : uploadProgress[file.name] === 'error' ? (
                                <ErrorIcon color="error" />
                            ) : uploadProgress[file.name] === 'uploading' ? (
                                <Typography variant="body2">Uploading...</Typography>
                            ) : (
                                <>
                                    <Typography variant="body2">Pending</Typography>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeFile(file.name)}
                                        sx={{ marginLeft: 'auto' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
                <LinearProgress variant="determinate" value={calculateOverallProgress()} sx={{ width: '100%', mt: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={() => { handleClose(); setFiles([]); setUploadProgress({}); }}>Close</Button>
                    <Button variant="contained" onClick={() => startUploads()}>Start Upload</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UploadImageModal;
