import { useState } from "react";
import { Box, Button, TextField, useMediaQuery, Typography, useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";

// Modal
import WaitingModal from '../Modals/WaitingModal';
import PasswordChangeDialog from "components/Dialogs/PasswordChangeDialog";

// API
import { useRegisterUserMutation, useLoginUserMutation, useUpdateUserPasswordMutation } from '../../../state/api';

// Custom theme
import formTheme from "./formTheme";

// Registration Schema
const registerSchema = yup.object().shape({
    firstName: yup.string().trim().matches(/^[a-zA-Z\s]+$/, "Invalid first name").required("First name is required"),
    lastName: yup.string().trim().matches(/^[a-zA-Z\s]+$/, "Invalid last name").required("Last name is required"),
    email: yup.string().trim().email("Invalid email address").required("Email is required"),
    description: yup.string().trim().max(500, "Description is too long"),
});

// Login Schema
const loginSchema = yup.object().shape({
  email: yup.string().trim().email("Invalid email address").required("Email is required"),
  password: yup.string().required("Password is required"),
});

// Initial values for the registration and login forms
const initialValuesRegister = {firstName: "", lastName: "", email: "", password: "", rePassword: ""};
const initialValuesLogin = {email: "",password: ""};

// Form component
const Form = ({ pageType, setPageType }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";

    // State for the waiting modal
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
    const [email, setEmail] = useState(''); // This is used to store the email for the password change dialog
    const [session, setSession] = useState(''); // This is used to store the session for the password change dialog
    const [isWaiting, setIsWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // API mutations
    const [registerUser] = useRegisterUserMutation();
    const [loginUser] = useLoginUserMutation();
    const [updateUserPassword] = useUpdateUserPasswordMutation();

    const handleRegister = async (values, onSubmitProps) => {
        // Step 1. Set the waiting state to true
        setIsWaiting(true);

        try {
            // Step 1. Call the registerUser mutation
            await registerUser(values).unwrap();

            // Reset the form and wait for the user to close the modal
            onSubmitProps.resetForm();
            setSuccess(true);

        } catch (error) {
            setError(true);
            setSuccess(false);
        }
        finally {
            // Hide the waiting modal
            setIsWaiting(false);
        }
    };

    const handleNewPasswordSubmit = async (newPassword, confirmPassword) => {
        const result = await updateUserPassword({ loginDetails: { session: session, password: newPassword, email: email }}).unwrap();
        if(result.success){
            // Step 3. Dispatch the setLogin action and navigate to the dashboard
            dispatch(setLogin({user: result.user, token: result.token}));
            navigate("/dashboard");
        }
        // Close the dialog
        setIsPasswordDialogOpen(false);
    };

    const handleLogin = async (values, onSubmitProps) => {
        try {
            // Step 1. Call the loginUser mutation
            const result = await loginUser(values).unwrap();

            // Step 2. Check if it succeeded and if a new password is required
            if(result.success && result.message === 'New password required'){
                setEmail(values.email);
                setSession(result.session);
                setIsPasswordDialogOpen(true);
                return;
            } else if(result.success) {
                // Step 2. Reset the form
                onSubmitProps.resetForm();

                // Step 3. Dispatch the setLogin action and navigate to the dashboard
                dispatch(setLogin({user: result.user, token: result.token}));
                navigate("/dashboard");
            }

        } catch (error) {
            // Handle error (e.g., show error message)
            setError(true);
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await handleLogin(values, onSubmitProps);
        if (isRegister) await handleRegister(values, onSubmitProps);
    };

    const handleClose = () => {
        // If it was a success, set the login view and reset the states\
        if (success){
            setPageType("login");
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
        }

        // If it was an error, close the modal and reset the states
        if (error){
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
        }
    }


    return (
        <ThemeProvider theme={formTheme}>

        <WaitingModal
            isOpen={isWaiting || success || error}
            message={success ? "We received your request! Check your email to confirm your account." :
                    (error ? "Error" : "Creating user...")}
            success={success}
            error={error}
            onClose={handleClose}
        />

        <PasswordChangeDialog
            isOpen={isPasswordDialogOpen}
            onClose={() => setIsPasswordDialogOpen(false)}
            onSubmit={handleNewPasswordSubmit}
        />

        <Formik
            onSubmit={handleFormSubmit}
            initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
            validationSchema={isLogin ? loginSchema : registerSchema}
        >
            {({values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm}) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="1rem"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        margin= "2rem 0"
                        sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        {/* REGISTRATION FORM (show two additional fields) */}
                        {isRegister && (
                            <>
                                <TextField
                                    label="First Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    name="firstName"
                                    error={
                                        Boolean(touched.firstName) && Boolean(errors.firstName)
                                    }
                                    helperText={touched.firstName && errors.firstName}
                                    sx={{ gridColumn: "span 2" }}
                                />

                                <TextField
                                    label="Last Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                    name="lastName"
                                    error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    sx={{ gridColumn: "span 2" }}
                                />

                                <TextField
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={Boolean(touched.email) && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    sx={{ gridColumn: "span 4" }}
                                />

                                <TextField
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    placeholder="Tell us about yourself..."
                                    multiline
                                    rows={6}
                                    sx={{ gridColumn: "span 4" }}
                                />
                            </>
                        )}

                        {/* EMAIL AND PASSWORD */}
                        {isRegister === false && (
                            <>
                                <TextField
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={Boolean(touched.email) && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    sx={{ gridColumn: "span 4" }}
                                />

                                <TextField
                                    label="Password"
                                    type="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={Boolean(touched.password) && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 4" }}
                                />
                            </>
                        )}

                    </Box>

                    {/* BUTTONS */}
                    <Box>
                        {/* LOGIN BUTTON */}
                        <Button
                            fullWidth
                            type="submit"
                            sx={{
                                backgroundColor: theme.palette.primary[600], // Set background color to primary color
                                textDecoration: 'none',
                                color: 'white',
                                margin: "1rem 0",
                                padding: "1rem",
                                fontSize: '1rem', // Adjust font size as needed
                                textTransform: 'none', // Prevent uppercase transformation
                                '&:hover': {
                                    textDecoration: 'none',
                                    backgroundColor: theme.palette.primary[800], // Darken button on hover
                                },
                            }}
                        >
                            {isLogin ? "LOGIN" : "REGISTER"}
                        </Button>

                        {/* SWITCH FORM TYPE */}
                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: theme.palette.primary[600],
                                "&:hover": {
                                    cursor: "pointer",
                                    color: theme.palette.primary[800],
                                },
                            }}
                        >
                            {isLogin
                                ? "Don't have an account? Sign Up here."
                                : "Already have an account? Login here."
                            }
                        </Typography>

                    </Box>
                </form>
            )}
        </Formik>
        </ThemeProvider>
    );
};

export default Form;