import React from 'react'
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'

// Icons
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DrawIcon from '@mui/icons-material/Draw';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TableViewIcon from '@mui/icons-material/TableView';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ChevronLeft } from '@mui/icons-material'
import { ChevronRightOutlined } from '@mui/icons-material'
import { HomeOutlined } from '@mui/icons-material'
import { Groups2Outlined } from '@mui/icons-material'
import { ReceiptLongOutlined } from '@mui/icons-material'
import { PublicOutlined } from '@mui/icons-material'
import { PointOfSaleOutlined } from '@mui/icons-material'
import { TodayOutlined } from '@mui/icons-material'
import { CalendarMonthOutlined } from '@mui/icons-material'
import { AdminPanelSettingsOutlined } from '@mui/icons-material'
import { TrendingUpOutlined } from '@mui/icons-material'
import { PieChartOutlined } from '@mui/icons-material'


// React
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import FlexBetween from "components/General/FlexBetween"

// Assets
const logoWhite = `/assets/images/logo/logoWhite.svg`;

// NavItems
const navItems = [
    {text: "Dashboard", icon: <HomeOutlined />},
    {text: "Project Management", icon: null},
    {text: "New Project", icon: <CreateNewFolderIcon />},
    {text: "Edit Project", icon: <AccountTreeIcon />},
    {text: "Train Model", icon: <EngineeringIcon />},
    {text: "Select Model", icon: <PsychologyIcon />},
    {text: "Annotatations", icon: null},
    {text: "Annotate", icon: <DrawIcon />},
    {text: "Progress", icon: <TrendingUpOutlined />},
    {text: "Details", icon: <TableViewIcon />},
    {text: "Stadistics", icon: <PieChartOutlined />},
    {text: "Management", icon: null},
    {text: "Account", icon: <AccountCircleIcon />},
    {text: "Collaborators", icon: <GroupIcon />}
];


const Sidebar = ({user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile}) => {
    // Sidebar configuration
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    // Effect to set the active link
    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);

    return (
        <Box component="nav">
            {/* Drawer if the sidebar is open */}
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant="persistent"
                    anchor="left"
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            color: theme.palette.secondary[0],
                            fontWeight: "bold",
                            backgroundColor: theme.palette.primary[600],
                            boxSixing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                        },
                    }}
                >
                    {/* Content of the drawer */}
                    <Box display="flex" flexDirection="column" height="100%">
                        {/* 1. Title with the logo */}
                        <Box m="1.5rem 2rem 2rem 3rem">
                            <FlexBetween color={theme.palette.secondary[0]}>
                                <Box display="flex" alignItems="center" height="100%" maxWidth="100%">
                                    <img src={logoWhite} alt="Totia Logo" style={{ width: '100%', height: '100%'}} />
                                </Box>

                                {/* If Mobile add a icon to hide the bar */}
                                {!isNonMobile && (
                                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                        <ChevronLeft />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>

                        {/* 2. List container with flexGrow and potentially padding at the bottom */}
                        <Box flexGrow={1} overflow="auto" backgroundColor={theme.palette.primary[600]}>
                            <List>
                                {navItems.map(({ text, icon }) => {
                                    // No icon, means it is a title
                                    if (!icon) {
                                        return (
                                            <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }} fontWeight="bold">
                                                {text}
                                            </Typography>
                                        );
                                    }

                                    // Lowercase the text (for the Links) and trim
                                    let lcText = text.toLowerCase();
                                    lcText = lcText.replace(/\s/g, "");
                                    return (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(`/${lcText}`);
                                                    setActive(lcText);
                                                }}
                                                sx={{
                                                    backgroundColor: active === lcText ? theme.palette.primary[100] : "transparent",
                                                    color: active === lcText ? theme.palette.primary[900] : theme.palette.secondary[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary[200],
                                                    },
                                                }}
                                            >
                                                {/* 1. Icon */}
                                                <ListItemIcon
                                                    sx={{
                                                        ml: "2rem",
                                                        color: active === lcText ? theme.palette.primary[600] : theme.palette.secondary[0],
                                                    }}
                                                >
                                                    {icon}
                                                </ListItemIcon>

                                                {/* 2. Text */}
                                                <ListItemText primary={text} />
                                                    {active === lcText && (
                                                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                                                    )}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>

                    </Box>

                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar
