import { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

// API
import { useSelector } from "react-redux";
import { useCheckProjectMutation } from 'state/api';

// Component to get the name of the project and check if it's available
const ProjectNameStep = ({ stepProps }) => {
    // Use theme
    const theme = useTheme();

    // Get the props
    const { projectName, setProjectName, isStepComplete, setIsStepComplete, title, subtitle } = stepProps;

    // State to check if it is the first attempt to check the project name
    const [firstAttempt, setFirstAttempt] = useState(true);

    // Get the user and the token from the state
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [checkProject] = useCheckProjectMutation();

    // Function to check the availability of the project name
    const checkAvailability = async (event) => {
        try {
            // Step 0. If project name is empty, return
            if (projectName === '') return;

            // Step 1. Send also the user email along with the attempt to check the project name
            const requestBody = {
                projectName,
                userEmail: user.email
            };

            // Step 2. Check the availability of the project name
            const result = await checkProject({ token, requestBody }).unwrap();

            if(result.isProjectNameAvailable) {
                // Check if the project name is available
                setIsStepComplete(true);
            }
            else {
                // Check if the project name is not available
                setIsStepComplete(false);
            }

            // Step 3. Set the first attempt to false
            setFirstAttempt(false);

        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Register failed', error);
        }
    };

    // Function to reset availability state when the project name is changed
    const handleProjectNameChange = (event) => {
        setProjectName(event.target.value);
        setIsStepComplete(false);
    };

    return (
        // Main Box Area
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>

            {/* Title and subtitle */}
            <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{subtitle}</Typography>
            </Box>

            {/* Text Field and Button to check */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap:5, mt:"2rem"}}>
                <TextField
                    sx={{ flex: 7 }}
                    required
                    fullWidth
                    label="Project Name"
                    variant="outlined"
                    value={projectName}
                    onChange={(e) => handleProjectNameChange(e)}
                />

                <Button
                    variant="contained"
                    onClick={checkAvailability}
                    sx={{
                        backgroundColor: theme.palette.primary[600],
                        textDecoration: 'none',
                        color: 'white',
                        padding: '10px 20px',
                        fontSize: '1rem',
                        borderRadius: '2%',
                        textTransform: 'none',
                        '&:hover': {
                            textDecoration: 'none',
                            backgroundColor: theme.palette.primary[800],
                        },
                    }}
                >
                    Check Availability
                </Button>
            </Box>

            {/* Availability message */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                <Typography
                    variant="h6"
                    // If firstAttempt, color is black, else, color is green if available, red if not
                    sx={{ color: firstAttempt ? theme.palette.secondary[1000] : isStepComplete ? theme.palette.success.main : theme.palette.error.main }}
                >
                    {firstAttempt ? '*Please introduce a valid name' : isStepComplete ? 'Available!' : 'Project name is not available. Please try another one'}
                </Typography>
            </Box>

        </Box>
    );
};

export default ProjectNameStep;