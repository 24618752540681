
import { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';

// Custom Components
import ItemSelector from "components/General/ItemSelector";

// From the API
import { useSelector } from "react-redux";
import { useGetProjectsAvailablesMutation } from "state/api";

const ProjectSelector = ({ handleNewProjectSelected, sx }) => {
    // Variables
    const theme = useTheme();
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [projectList, setProjectList] = useState([]);

    // API calls
    const [getProjectsAvailables] = useGetProjectsAvailablesMutation();

    // Fetch the projects when the user and the token are available
    useEffect(() => {

        // Function to fetch the projects just once at the beginning
        const fetchData = async () => {

            // Ensure the user and the token are available
            if (user && token && user.email) {
                try {
                    // Fetch the list of projects availables for this user
                    const result = await getProjectsAvailables({ token: token, requestBody: { email: user.email } }).unwrap();
                    if (result.success) {
                        setProjectList(result.data);
                    } else {
                        setProjectList([]);
                        console.error('Error fetching projects:', result.error);
                    }
                } catch (error) {
                    console.error('Error fetching projects:', error);
                }
            }
        };

        fetchData();
    }, [user, token]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{width: '100%', height: '100%', maxWidth: 360, p:theme.spacing(1), ...sx}}>
            <ItemSelector items={projectList} selectionChanged={handleNewProjectSelected} label="Project"/>
        </Box>
    );
}

export default ProjectSelector;
