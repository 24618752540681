import React, { useEffect, useRef } from 'react';
import { Modal, Box, CircularProgress, Typography, useTheme } from '@mui/material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    heigh: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const ProgressModal = ({ progress, total, isOpen, onClose }) => {
    const theme = useTheme();
    const isFirstRender = useRef(true);
    const progressPercent = Math.round((progress / total) * 100);

    // Effect to call onClose when isOpen changes from true to false
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else if (!isOpen) {
            onClose();
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            open={isOpen}
            aria-labelledby="progress-modal-title"
            aria-describedby="progress-modal-description"
        >
            {/* Apply the modal style */}
            <Box sx={modalStyle}>

                {/* Title */}
                <Typography variant="h2" color={theme.palette.primary[900]} fontWeight="bold" sx={{ mb: "5px" }}>
                    Preparing the project
                </Typography>

                {/* Circular Progress Bar */}
                <Box sx={{ position: 'relative', display: 'inline-flex', marginTop: '20px' }}>
                    <CircularProgress variant="determinate" value={progressPercent} size={180} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {/* Progress % in the middle */}
                        <Typography variant="h2" color={theme.palette.secondary[700]} fontWeight="bold">
                            {`${progressPercent}%`}
                        </Typography>

                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProgressModal;
