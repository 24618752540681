import React, { useState } from "react";
import FlexBetween from "components/General/FlexBetween";
import Header from "components/General/Header";
import { Box, useTheme, useMediaQuery, Button } from "@mui/material";

// Import Icon to restart the form
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// Import the editor and the context
import NewProjectForm from "components/Forms/NewProjectForm/NewProjectForm";


const NewProject = () => {
    const theme = useTheme();
    const [formKey, setFormKey] = useState(Date.now());
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));

    // Function to reset the form
    const resetForm = () => {
        setFormKey(Date.now());
    };

    return (
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>
            {/* 1. Header */}
            <FlexBetween>
                <Header title="New Project" subtitle="Follow all the steps to create a new project" />

                <Box>
                    <Button
                        onClick={resetForm}
                        sx={{
                        backgroundColor: theme.palette.primary[600],
                        color: theme.palette.secondary[0],
                        textDecoration: 'none',
                        fontSize: "1rem",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        textTransform: 'none', // Prevent uppercase transformation
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: theme.palette.primary[800], // Darken button on hover
                            },
                        }}
                    >
                        <RestartAltIcon sx={{ mr: "10px" }} />
                        Restart form
                    </Button>
                </Box>
            </FlexBetween>

            {/* 2. Main Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.1. ROW 1. Main Area */}
                    <Box gridColumn="3 / span 8" gridRow="span 6" p={theme.spacing(1)} color={theme.palette.secondary[1000]}>
                        <NewProjectForm key={formKey} />
                    </Box>
            
                </Box>

            </Box>
        </Box>
  );
};

export default NewProject;