import { useState, useEffect, useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useGetImageInformationMutation, useUpdateImageInformationMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to fetch and update the image information
function useImageInformation(projectId, imageId, onDisplayNameUpdate) {

    // Variables
    const token = useSelector(selectToken);
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState('');
    const [displayName, setDisplayName] = useState('');

    // State to store fetched information for comparison
    const [fetchedInfo, setFetchedInfo] = useState({ projectId: '', imageId: '', tags: [], description: '', displayName: '' });

    const [getImageInformation] = useGetImageInformationMutation();
    const [updateImageInformation] = useUpdateImageInformationMutation();

    // Function to fetch image information from the API
    const fetchInformation = useCallback(async () => {
        try {
            const response = await getImageInformation({ token, requestBody: { projectId, imageId } }).unwrap();
            if (response.success) {
                console.log('Fetched image information:', response);
                setTags(response.tags);
                setDescription(response.description);
                setDisplayName(response.displayName);
                setFetchedInfo({ projectId, imageId, tags: response.tags, description: response.description, displayName: response.displayName });
            } else {
                console.error('Failed to fetch image information:', response.message);
                setTags([]);
                setDescription('');
                setDisplayName('');
                setFetchedInfo({ projectId: '', imageId: '', tags: [], description: '', displayName: '' });
            }
        } catch (error) {
            console.error('Failed to fetch image information:', error);
            setTags([]);
            setDescription('');
            setDisplayName('');
            setFetchedInfo({ projectId: '', imageId: '', tags: [], description: '', displayName: '' });
        }
    }, [projectId, imageId, token]); // eslint-disable-line react-hooks/exhaustive-deps


    // Function to update image information through the API
    const updateInformation = useCallback(async (requestProjectId, requestImageId) => {
        try {
            const response = await updateImageInformation({ token, requestBody: { projectId: requestProjectId, imageId: requestImageId, tags, description, displayName } }).unwrap();
            if (response.success) {
            } else {
                console.error('Failed to update image information:', response.message);
            }
        } catch (error) {
            console.error('Failed to update image information:', error);
        }
    }, [projectId, imageId, tags, description, displayName, token]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to fetch image information when projectId, imageId, or token changes
    useEffect(() => {
        if (projectId && imageId) {
            // If the projectId and imageId doesnt match the fetched information, update first before fetching new data
            if ((projectId !== fetchedInfo.projectId || imageId !== fetchedInfo.imageId) && (fetchedInfo.projectId !== '' || fetchedInfo.imageId !== '')) {
                // Check if there are actual changes to update on tags, description, or displayName
                if (tags !== fetchedInfo.tags || description !== fetchedInfo.description || displayName !== fetchedInfo.displayName) {
                    updateInformation(fetchedInfo.projectId, fetchedInfo.imageId);

                    // If the displayName changes, call the callback
                    if (displayName !== fetchedInfo.displayName) {
                        onDisplayNameUpdate();
                    }
                }
            }
            fetchInformation();
        }
    }, [projectId, imageId, fetchInformation]); // eslint-disable-line react-hooks/exhaustive-deps


    // Function to clear the information when the user delete an image
    const forceClear = () => {
        setTags([]);
        setDescription('');
        setDisplayName('');
        setFetchedInfo({ projectId: '', imageId: '', tags: [], description: '', displayName: '' });
    }

    // Function to force an update if the user clicks on the same image
    const forceUpdate = () => {
        updateInformation(fetchedInfo.projectId, fetchedInfo.imageId);
    }

    return { tags, setTags, description, setDescription, displayName, setDisplayName, forceUpdate, forceClear };
}

export default useImageInformation;