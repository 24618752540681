import React from 'react';
import { useTheme } from "@mui/material";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, Label } from 'recharts';

// Custom Label in the middle of the pie chart
const CustomLabel = ({ cx, cy, value }) => {
    return (
      <g>
        <text x={cx} y={cy} fill="black" textAnchor="middle" dominantBaseline="central" alignmentBaseline="middle">
          <tspan x={cx} dy="-10">Images</tspan>
          <tspan x={cx} dy="20" fontSize="20" fontWeight="bold">{value}</tspan>
        </text>
      </g>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: theme.palette.background.paper,
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
            }}>
                <p>{label}</p>
                <p>{`${payload[0].name} : `}<strong>{`${payload[0].value}`}</strong></p>
            </div>
        );
    }

    return null;
};

const ProjectImages = ({ data, colors }) => {
    const totalImages = data.reduce((acc, cur) => acc + cur.value, 0);

    return(
        <ResponsiveContainer width="100%" height="100%">
            <PieChart margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>

                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius="60%"
                    labelLine={false}
                    label={({ cx, cy }) => <CustomLabel cx={cx} cy={cy} value={totalImages} />}
                    outerRadius="90%"
                    fill="#8884d8"
                    dataKey="value"
                    onClick={() => {}}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend align="center" verticalAlign="bottom" layout="horizontal" height={50} />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default ProjectImages;