// React and MUI imports
import React, { useEffect, useState} from 'react';
import { Box, Alert, ListItemButton, MenuItem, Popover, Select, List, ListItemIcon, ListItemText,
         BottomNavigationAction, BottomNavigation, Tooltip, Typography, Slider} from '@mui/material';

// Import icons
import PanToolIcon from '@mui/icons-material/PanTool';
import BrushIcon from '@mui/icons-material/Brush';
import SearchIcon from '@mui/icons-material/Search';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CircularProgress from '@mui/material/CircularProgress';
import TuneIcon from '@mui/icons-material/Tune';

const Mode = {
    NONE: 'NONE',
    DRAWING: 'DRAWING',
    PANNING: 'PANNING',
    INSPECTION: 'INSPECTION',
    DELETE: 'DELETE',
    ADJUST: 'ADJUST',
    ASSISTANT: 'ASSISTANT',
    DOWNLOAD: 'DOWNLOAD',
};

const navigationActions = [
    { label: 'Move', icon: <PanToolIcon /> },
    { label: 'Label', icon: <LocalOfferIcon /> },
    { label: 'Annotate', icon: <BrushIcon /> },
    { label: 'Brush Size', icon: <UnfoldMoreIcon /> },
    { label: 'Inspect', icon: <SearchIcon /> },
    { label: 'Adjust', icon: <TuneIcon /> },
    { label: 'Assistant', icon: <AutoFixHighIcon /> },
    { label: 'Download', icon: <DownloadIcon /> },
    { label: 'Delete', icon: <DeleteIcon /> },
];

const ImageOptionBar = ({ state, updateState, downloadImage, finalizeShape, labels, magicAssistant, modelReady, annotationSelected }) => {

    // Toolbar value and disable state
    const [value, setValue] = useState(-1);
    const [disabledButtons, setDisabledButtons] = useState({});

    // Altert state
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        text: "",
        severity: "warning"
    });

    // UseEffect to update the Assistant button state based on modelReady
    useEffect(() => {
        const assistantIndex = navigationActions.findIndex(action => action.label === 'Assistant');
        if (modelReady === 1) {
            // Set the Assistant icon back to its default when the model is ready
            navigationActions[assistantIndex].icon = <AutoFixHighIcon />;
        } else if (modelReady === 2) {
            // Set the Assistant icon to a spinning wheel when the model is not ready
            navigationActions[assistantIndex].icon = <CircularProgress size={24} />;
        }
    }, [modelReady]);

    // Check if the state.toolbarDisabled is true or false
    useEffect(() => {
        if (state.toolbarDisabled) {
            setDisabledButtons(navigationActions.reduce((acc, action, index) => {
                acc[action.label] = true;
                return acc;
            }, {}));
        } else {
            // Enable all the buttons and set the mode to MOVE
            setDisabledButtons({});
            handleToolbarChange(null, 0);
        }
    }, [state.toolbarDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

    // Toolbar options
    const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLabelOpen, setIsLabelOpen] = useState(false);
    const [isStrokeSizeOpen, setIsStrokeSizeOpen] = useState(false);
    const [isAdjustmentsOpen, setIsAdjustmentsOpen] = useState(false);

    // Handle toolbar change
    const handleToolbarChange = async (event, newValue) => {
        // Get the previous label and the new label
        const previousLabel = (value !== -1) ? navigationActions[value].label : '';
        const newLabel = (newValue !== -1) ? navigationActions[newValue].label : '';

        // If mode is not inspect, remove the highlighted shape
        if (newLabel !== Mode.INSPECTION) {
            annotationSelected(null);
            updateState({ highlightedShapeId: null })
        }


        // Step 2. Set the current mode based on the selected button
        switch (navigationActions[newValue].label) {
            case 'Move':
                // If previous mode was Move, toggle it off
                if (previousLabel === 'Move') {
                    updateState({ currentMode: Mode.NONE });
                    setValue(-1);
                } else {
                    updateState({ currentMode: Mode.PANNING });
                    setValue(newValue);
                }
                break;

            case 'Annotate':
            case 'Done':
                // If previous mode was Annotate, toggle it off
                if (newLabel === 'Done' ) {
                    // Change back the action.label to Annotate
                    navigationActions[newValue].label = 'Annotate';
                    finalizeShape();
                    updateState({ currentMode: Mode.PANNING });
                    setValue(0);

                    // Enable all the buttons
                    const newDisabledButtons = navigationActions.reduce((acc, action, index) => {
                        acc[action.label] = false;
                        return acc;
                    }, {});
                    setDisabledButtons(newDisabledButtons);

                } if (newLabel === 'Annotate' ) {
                    // Check if there is a label selected other wise show an alert
                    if (state.selectedLabel.text === '') {
                        setAlertInfo({
                            show: true,
                            text: "Please select a label before annotating.",
                            severity: "warning"
                        });
                        return;
                    }

                    // Change the action.label to Done
                    navigationActions[newValue].label = 'Done';
                    updateState({ currentMode: Mode.DRAWING });
                    setValue(newValue);

                    // Disable all the buttons except the current one
                    const newDisabledButtons = navigationActions.reduce((acc, action, index) => {
                        acc[action.label] = action.label !== 'Done';
                        return acc;
                    }, {});
                    setDisabledButtons(newDisabledButtons);
                }
                break;

            case 'Inspect':
                // If previous mode was Inspect, toggle it off
                if (previousLabel === 'Inspect') {
                    updateState({ currentMode: Mode.PANNING, highlightedShapeId: null});
                    setValue(0);
                } else {
                    updateState({ currentMode: Mode.INSPECTION });
                    setValue(newValue);
                }
                break;

            case 'Brush Size':
                setAnchorEl(event.currentTarget);
                setIsStrokeSizeOpen(true);
                setValue(newValue);
                break;

            case 'Label':
                setAnchorEl(event.currentTarget);
                setIsLabelOpen(true);
                setValue(newValue);
                break;

            case 'Assistant':
                const result = await magicAssistant();
                if (result === -1) {
                    setAlertInfo({
                        show: true,
                        text: "No models availables or selected.",
                        severity: "warning"
                    });
                }
                setValue(0);
                break;

            case 'Delete':
                // If previous mode was Delete, toggle it off
                if (previousLabel === 'Delete') {
                    updateState({ currentMode: Mode.PANNING });
                    setValue(0);
                } else {
                    updateState({ currentMode: Mode.DELETE });
                    setValue(newValue);
                }
                break;

            case 'Adjust':
                setAnchorEl(event.currentTarget);
                setIsAdjustmentsOpen(true);
                setValue(0);
                break;

            case 'Download':
                downloadImage();
                setValue(value);
                break;

            default:
                setValue(newValue);
        }
    }

    // Return the Toolbar
    return(
        <>
            <Box sx={{ width: "100%"}}>
                <BottomNavigation showLabels value={value} onChange={handleToolbarChange}>
                    {navigationActions.map((action, index) => (
                        <BottomNavigationAction key={index} label={action.label} icon={action.icon} disabled={alertInfo.show || !!disabledButtons[action.label]} />
                    ))}
                </BottomNavigation>
            </Box>

            {/* Alert */}
            {alertInfo.show &&
                <Alert severity="warning" onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
                    {alertInfo.text}
                </Alert>
            }

            {/* Information on Annotation hover */}
            {state.tooltipOpen && (
                <div style={{ position: 'fixed', left: state.tooltipPosition.x, top: state.tooltipPosition.y }}>
                    <Tooltip
                        title={state.tooltipContent}
                        open={state.tooltipOpen}
                        placement="top"
                    >
                        <div></div>
                    </Tooltip>
                </div>
            )}

            {/* Brush Size Popover */}
            <Popover
                open={isStrokeSizeOpen}
                anchorEl={anchorEl}
                onClose={() => {setIsStrokeSizeOpen(false); handleToolbarChange(null, 0);}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}
            >
                <Box>
                    <Select
                        value={state.strokeSize}
                        onChange={(e) => {updateState({strokeSize: e.target.value}); setIsStrokeSizeOpen(false); handleToolbarChange(null, 0);}}
                        fullWidth
                    >
                        {strokeSizes.map(size => (
                            <MenuItem key={size} value={size}>{size}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </Popover>

            {/* Label Popover */}
            <Popover
                open={isLabelOpen}
                anchorEl={anchorEl}
                onClose={() => setIsLabelOpen(false)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Box sx={{ width: 'auto', bgcolor: 'background.paper' }}>
                    <List>
                        {labels.map((label, index) => (
                            <ListItemButton key={index} onClick={() => {
                                updateState({selectedLabel: label});
                                setIsLabelOpen(false);
                                handleToolbarChange(null, 0);
                            }}>
                                <ListItemIcon>
                                    <Box sx={{ width: 24, height: 24, bgcolor: label.color, border: "1px solid #000" }} />
                                </ListItemIcon>
                                <ListItemText primary={label.text} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Popover>

            {/* Adjustments Popover */}
            <Popover
                open={isAdjustmentsOpen}
                anchorEl={anchorEl}
                onClose={() => setIsAdjustmentsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2, bgcolor: 'background.paper', width:"300px"}}>
                    <Typography variant="caption">Brightness</Typography>
                    <Slider
                        value={state.brightness}
                        onChange={(e, newValue) => updateState({brightness: newValue})}
                        aria-labelledby="brightness-slider"
                        valueLabelDisplay="auto"
                        step={0.001}
                        min={-0.6}
                        max={0.6}
                    />
                    <Typography variant="caption">Contrast</Typography>
                    <Slider
                        value={state.contrast}
                        onChange={(e, newValue) => updateState({contrast: newValue})}
                        aria-labelledby="contrast-slider"
                        valueLabelDisplay="auto"
                        step={0.5}
                        min={-100}
                        max={100}
                    />
                </Box>
            </Popover>
        </>
    )
}

export default ImageOptionBar;
