import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Paper, Skeleton, useTheme, useMediaQuery } from "@mui/material";

// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// Custom Components
import FlexBetween from "components/General/FlexBetween";
import Header from "components/General/Header";
import ProjectSelector from 'components/General/ProjectSelector';
import ProjectImagesList from "components/Lists/ProjectImagesList";
import ProjectImages from "components/Graphs/ProjectImages";
import ImageViewer from "components/ImageEditor/ImageViewer";
import UploadImageModal from 'components/Forms/Modals/UploadImageModal';
import ProgressModal from "components/Forms/Modals/ProgressModal";

// Custom Hooks
import useFetchImagesList from "components/Hooks/useFetchImagesList";
import useRefreshImagesToken from "components/Hooks/useRefreshImagesToken";
import useImageInformation from "components/Hooks/useImageInformation";

// Main Component
const EditProject = () => {
    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));
    const [project, setProject] = useState(null);
    const [image, setImage] = useState(null);
    const [refreshImagesList, setRefreshImagesList] = useState(false);
    const {data: imagesList} = useFetchImagesList(project?.id, refreshImagesList);
    const {loading: tokensRefreshing, progress: tokensProgress, refreshTokens} = useRefreshImagesToken(project?.id, imagesList);
    const {tags, description, displayName, setTags, setDescription, setDisplayName, forceUpdate, forceClear } = useImageInformation(project?.id, image?.id, () => {setRefreshImagesList(!refreshImagesList)});
    const [chartData, setChartData] = useState(null);

    // Recalculate the chart data when the imagesList is available
    useEffect(() => {
        if (imagesList) {
            // Count the annotated and not annotated images
            const annotatedImages = imagesList.filter(image => image.isAnnotated);
            const notAnnotatedImages = imagesList.filter(image => !image.isAnnotated);

            // Set the chart data
            setChartData([
                { name: 'Annotated', value: annotatedImages.length },
                { name: 'Not Annotated', value: notAnnotatedImages.length }
            ]);

            // Refresh the tokens if the time to expire is less than 120 minutes
            if (imagesList.length > 0) {
                // Check if the field accessTokenExpireDate is empty for any of the images (if so, refresh the tokens)
                if (imagesList.some(image => image.accessTokenExpireDate === '')) {
                    refreshTokens();
                }

                else{
                    // Get the index of the oldest image token date (the closest to expire)
                    const oldestTokenIndex = imagesList.reduce((oldestIndex, image, currentIndex) => {
                        if (image.accessTokenExpireDate < imagesList[oldestIndex].accessTokenExpireDate) {
                            return currentIndex;
                        } else {
                            return oldestIndex;
                        }
                    }, 0);

                    // Compute the difference between the token date and the current date in minutes
                    const tokenDate = new Date(imagesList[oldestTokenIndex].accessTokenExpireDate);
                    const currentDate = new Date();

                    // Check the diference
                    const timeToExpire = Math.round((tokenDate - currentDate) / 60000);
                    if (timeToExpire < 120) {
                        refreshTokens();
                    }
                }
            }

        } else {
            setChartData(null);
        }
    }, [imagesList]); // eslint-disable-line react-hooks/exhaustive-deps


    // States to manage the upload modal
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const handleOpenUploadModal = () => setUploadModalOpen(true);
    const handleCloseUploadModal = () => {setUploadModalOpen(false); setRefreshImagesList(!refreshImagesList);};

    const handleNewProjectSelected = (project) => {
        setProject(project);
        forceClear();
        setRefreshImagesList(!refreshImagesList);
        setImage(null);
    }

    const handleNewImageSelected = (selectedImage) => {

        // If the selectedImage is null (an image was deleted), reset the current imageList
        if (selectedImage === null) {
            // Reset the current image
            forceClear();
            setRefreshImagesList(!refreshImagesList)
            setImage(null);
        }

        // If the selectedImage is equal to the image, force an update
        else if (selectedImage.id === image?.id) {
            forceUpdate();
        }

        else {
            // Set the current image
            setImage(selectedImage);
        }
    }

    return (
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>
            {/* 0. Modals */}
            <ProgressModal progress={tokensProgress} total={imagesList?.length} isOpen={tokensRefreshing} onClose={() => {setRefreshImagesList(!refreshImagesList)}}/>

            {/* 1. Header */}
            <FlexBetween>
                <Header title="Edit Project" subtitle="Select the project you want to edit" />
                <ProjectSelector handleNewProjectSelected={handleNewProjectSelected} />
            </FlexBetween>

            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.3. Stadistics */}
                    <Box gridColumn="span 3" gridRow="span 4" color={theme.palette.secondary[1000]} >

                        <Paper
                            elevation={3}
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '10px',
                            }}
                        >

                            {/* Last Update */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap:"1rem", justifyContent:"center", mt:"1rem"}}>
                                <EditCalendarIcon sx={{ color: theme.palette.secondary[800]}} />
                                <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                                {project?.updatedAt
                                    ? new Date(project?.updatedAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})
                                    : 'Last Update'
                                }
                                </Typography>
                            </Box>

                            {/* Pie Chart */}
                            {chartData
                                ? <ProjectImages data={chartData} colors={[theme.palette.primary[600], '#bf0603']}/>
                                : <Skeleton variant="rectangular" width="100%" height="100%" style={{ margin: '1rem' }}/>
                            }


                            <Box sx={{ display: 'flex', alignItems: 'center', gap:"1rem", justifyContent:"center", mb:"1.5rem"}}>
                                <Button
                                    variant="contained"
                                    onClick={handleOpenUploadModal}
                                    disabled={!project?.id}
                                    sx={{
                                        backgroundColor: theme.palette.primary[600],
                                        color: theme.palette.secondary[0],
                                        textDecoration: 'none',
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        textTransform: 'none', // Prevent uppercase transformation
                                            '&:hover': {
                                                textDecoration: 'none',
                                                backgroundColor: theme.palette.primary[800], // Darken button on hover
                                            },
                                        }}
                                    >
                                        <CloudUploadIcon sx={{ mr: "10px" }} />
                                        Upload New Image
                                    </Button>

                                    {/* Upload Image Modal */}
                                    <UploadImageModal
                                        ownerEmail={project?.ownerEmail}
                                        projectId={project?.id}
                                        open={isUploadModalOpen}
                                        handleClose={handleCloseUploadModal}
                                    />
                            </Box>
                        </Paper>
                        </Box>

                        {/* 2.2. Image preview and tags editor */}
                        <Box gridColumn="span 9" gridRow="span 8" color={theme.palette.secondary[1000]}>
                            <ImageViewer
                                accessToken={image ? image.accessToken : ''}
                                tags={tags}
                                description={description}
                                displayName={displayName}
                                setTags={setTags}
                                setDescription={setDescription}
                                setDisplayName={setDisplayName}
                            />
                        </Box>

                        {/* 2.4. List of images availables */}
                        <Box gridColumn="span 3" gridRow="span 4" color={theme.palette.secondary[1000]}>
                            <ProjectImagesList
                                project={project}
                                imagesList={imagesList}
                                newImageSelected={handleNewImageSelected}
                            />
                        </Box>

                    </Box>
            </Box>
        </Box>
    );
};

export default EditProject;