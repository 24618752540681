import React, { useEffect, useState } from 'react';
import { Paper, TextField, Button, Box, Avatar, Typography, useTheme, useMediaQuery } from '@mui/material';

// Components
import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import UserProfilePicture from 'components/General/UserProfilePicture';
import BasicUserInformation from 'components/Dashboards/BasicUserInformation';
import AdvanceUserInformation from 'components/Dashboards/AdvanceUserInformation';


// Component
const Account = () => {

    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));

    // Render the component
    return(
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 1. Header */}
            <FlexBetween>
                    <Header title="Account" subtitle="Edit you profile" />
            </FlexBetween>

            {/* 2. Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.1. User Profile */}
                    <Box gridColumn="span 3" gridRow="span 5" color={theme.palette.secondary[1000]}>
                        <UserProfilePicture />
                    </Box>

                    {/* 2.2 Main Information Area */}
                    <Box gridColumn="span 9" gridRow="span 9" color={theme.palette.secondary[1000]} backgroundColor='grey'>
                        <AdvanceUserInformation />
                    </Box>

                    {/* 2.3 Basic User Information */}
                    <Box gridColumn="span 3" gridRow="span 4" color={theme.palette.secondary[1000]}>
                        <BasicUserInformation />
                    </Box>


                </Box>
            </Box>
        </Box>
    );
};

export default Account;