import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const PasswordChangeDialog = ({ isOpen, onClose, onSubmit }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const handleSubmit = () => {
        let isValid = true;
        let newErrors = { newPassword: '', confirmPassword: '' };

        // Define regex patterns for validation
        const hasNumber = /\d/;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
        const hasCapitalLetter = /[A-Z]/;

        // Reset errors
        setErrors({ newPassword: '', confirmPassword: '' });

        // Validation checks
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
            isValid = false;
        }
        if (newPassword.length < 8) {
            newErrors.newPassword = "Password must be at least 8 characters long.";
            isValid = false;
        }
        if (!hasNumber.test(newPassword)) {
            newErrors.newPassword += " Must contain at least one number.";
            isValid = false;
        }
        if (!hasSpecialChar.test(newPassword)) {
            newErrors.newPassword += " Must contain at least one special character.";
            isValid = false;
        }
        if (!hasCapitalLetter.test(newPassword)) {
            newErrors.newPassword += " Must contain at least one uppercase letter.";
            isValid = false;
        }

        if (!isValid) {
            setErrors(newErrors);
            return;
        }

        // If all checks pass, call the onSubmit function
        onSubmit(newPassword, confirmPassword);
        onClose(); // Close dialog after successful validation and submission
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <TextField
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    margin="dense"
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    margin="dense"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordChangeDialog;
