// Color palette
const tokensDark = {
    grey: {
        0: "#ffffff",
        10: "#f5f5f5",
        50: "#f0f0f0",
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        1000: "#000000",
    },

    primary: {
        // Medical Blue Pallete
        100: "#bbdefb",
        200: "#90caf9",
        300: "#64b5f6",
        400: "#42a5f5",
        500: "#2196f3",
        600: "#1e88e5",
        700: "#1976d2",
        800: "#1565c0",
        900: "#0d47a1",
    },

    secondary: {
        // Complementary Orange Pallete
        0: "#ffffff",
        10: "#f5f5f5",
        50: "#f0f0f0",
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        1000: "#000000",
    },

    failure: {
        100: "#f8d3d3",
        200: "#f1a7a7",
        300: "#ea7b7b",
        400: "#e34f4f",
        500: "#dc2323",
        600: "#b01c1c",
        700: "#841515",
        800: "#580e0e",
        900: "#2c0707",
    },

    success: {
        100: "#def1de",
        200: "#bee3be",
        300: "#9dd49d",
        400: "#7dc67d",
        500: "#5cb85c",
        600: "#4a934a",
        700: "#376e37",
        800: "#254a25",
        900: "#122512"
    },

};

// MUI theme settings
const themeSettings = (mode) => {
    return {
        palette: {
            // Palette values for dark mode
            primary: {
                ...tokensDark.primary,
                main: tokensDark.primary[400],
                light: tokensDark.primary[400],
            },
            secondary: {
                ...tokensDark.secondary,
                main: tokensDark.secondary[0],
            },
            neutral: {
                ...tokensDark.grey,
                main: tokensDark.grey[500],
            },
            background: {
                default: "#f5f5f5",
                alt: "#42a5f5",
            },
            failure: {
                ...tokensDark.failure,
                main: tokensDark.failure[500],
            },
            success: {
                ...tokensDark.success,
                main: tokensDark.success[500],
            },
        },

        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            h1: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 40},
            h2: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 32},
            h3: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 24},
            h4: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 20},
            h5: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 16},
            h6: {fontFamily: ["Inter", "sans-serif"].join(","), fontSize: 14},
        },
    };
};

//  Exporting the color palette and theme settings for MUI
export { themeSettings };