import { useState, useEffect, useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useDialog } from 'components/General/CustomDialog';
import { useGetTrainingImagesListMutation, useTrainRequestMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;
const selectUser = state => state.persistedReducer.user;

// Custom hook to fetch the list of images availables in a project along with some stadistics to prepare the training data
function usePrepareTrainingData(projectId, refresh) {

    // Variables
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const [getTrainingImagesList] = useGetTrainingImagesListMutation();
    const [trainRequest] = useTrainRequestMutation();
    const { requestConfirm } = useDialog();

    // States
    const [fullImageList, setFullImageList] = useState([]);
    const [trainList, setTrainList] = useState([]);
    const [validationList, setValidationList] = useState([]);
    const [progressInformation, setProgressInformation] = useState({ isWaiting: false, success: false, error: false, message: '' });

    // Fetch the image list when the projectId is available (or changed)
    useEffect(() => {
        // Early return if projectId is not available
        if (!projectId) return;

        // Function to fetch the images list
        const fetchData = async () => {

            // If projectId is available and not ''
            try {
                const response = await getTrainingImagesList({ token, requestBody: { projectId: projectId } }).unwrap();
                if (response.success) {
                    setFullImageList(response.data);
                } else {
                    setFullImageList([]);
                }
            } catch (error) {
                setFullImageList([]);
            }
        };

        fetchData();
    }, [projectId, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    // Function to create a new split
    const splitDataset = useCallback(async (splitPercentage=80.0) => {

        // Step 1. Shuffle the list
        const shuffledImages = [...fullImageList].sort(() => Math.random() - 0.5);

        // Step 2. Split the list of objects into two sets
        const splitIndex = Math.floor((splitPercentage / 100) * shuffledImages.length);

        // Step 3. Set the training and validation list
        setTrainList(shuffledImages.slice(0, splitIndex));
        setValidationList(shuffledImages.slice(splitIndex));

    }, [fullImageList]); // eslint-disable-line react-hooks/exhaustive-deps


    // Split once the fullImageList is available
    useEffect(() => {
        if (fullImageList.length > 0) {
            splitDataset();
        }
        else{
            setTrainList([]);
            setValidationList([]);
            setProgressInformation({ isWaiting: false, success: false, error: false, message: '' });
        }
    }, [fullImageList, splitDataset]);

    // Function to submit a new training request
    const submitTrainingRequest = useCallback(async () => {
        // Step 1. Show a modal to confirm the training request
        const confirmResult = await requestConfirm("Are you sure you want to train a new model?");
        if (confirmResult) {
            // Step 1. Set the waiting flag to true
            setProgressInformation({ isWaiting: true, success: false, error: false, message: 'Submitting...' });

            // Step 2. Prepare the request body
            const requestBody = {projectId: projectId, requestBy: user.email, trainingSet: trainList, validationSet: validationList};

            // Step 3. Try to submit the request
            try {
                const response = await trainRequest({ token, requestBody }).unwrap();
                if (response.success) {
                    setProgressInformation(prevState => ({ ...prevState, isWaiting: false, success: true, message: 'Success!' }));
                } else {
                    setProgressInformation(prevState => ({ ...prevState, isWaiting: false, success: false, error: true, message: 'There is a pending request for this project' }));
                }
            } catch (error) {
                let errorMessage = 'Error';
                if (error.data && error.data.message === 'Pending') {
                    errorMessage = 'There is a pending request for this project';
                }
                setProgressInformation(prevState => ({ ...prevState, isWaiting: false, success: false, error: true, message: errorMessage }));
            }
        }
    }, [projectId, trainList, validationList, token]); // eslint-disable-line react-hooks/exhaustive-deps

    return { fullImageList, trainList, validationList, progressInformation, splitDataset, setProgressInformation, setTrainList, setValidationList, submitTrainingRequest};
}

export default usePrepareTrainingData;