import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';

// Icons
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';

const InfoCard = ({ icon: Icon, iconProps, title, subtitle }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{width: '100%', padding: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
            <Icon sx={{ color: theme.palette.secondary[800], fontSize: '4rem', ...iconProps }} />

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                <Typography variant="h4" color={theme.palette.secondary[500]}>
                    {title}
                </Typography>
                <Typography variant="h6" fontWeight='bold' color={theme.palette.primary[600]}>
                    {subtitle}
                </Typography>
            </Box>
        </Paper>
    );
};

const TrainSizesCard = ({ trainingSize, validationSize }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{width: '100%', padding: '1rem', display: 'flex', justifyContent:"center", flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>

            <Box width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {trainingSize}
                </Typography>
                <Typography variant="h6" color={theme.palette.secondary[500]}>
                    Training
                </Typography>
            </Box>

            <Box width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {validationSize}
                </Typography>
                <Typography variant="h6" color={theme.palette.secondary[500]}>
                    Validation
                </Typography>
            </Box>
        </Paper>
    );
}


const PerformanceCard = ({ performance }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{width: '100%', padding: '1rem', display: 'flex', justifyContent:"center", flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>

            <Box width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {`${(performance * 100).toFixed(2)}%`}
                </Typography>
                <Typography variant="h6" color={theme.palette.secondary[500]}>
                    Global Performance
                </Typography>
            </Box>

        </Paper>
    );
}

const ModelGeneralPerformance = ({ selectedModel }) => {
    const theme = useTheme();

    const hours = selectedModel ? selectedModel.performance.trainingTime / 3600 : 0;
    const minutes = selectedModel ? (selectedModel.performance.trainingTime % 3600) / 60 : 0;

    return (
        <Paper elevation={3} sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px'}}>

            {/* Section Title */}
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0 0',
                    padding: '1rem',
                    gap: "1rem",
                    backgroundColor: theme.palette.secondary[50],
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                }}
                >
                    <ListAltIcon sx={{ color: theme.palette.secondary[800]}} />
                    <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                        General Information
                    </Typography>
            </Paper>

            {/* Content when selected model is null */}
            {selectedModel === null && (
                <Box
                    width='100%'
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    color={theme.palette.secondary[1000]}
                    >
                    <Typography variant="h6" color={theme.palette.primary[700]}>
                        No model selected
                    </Typography>
                    <Typography variant="body1" color={theme.palette.primary[700]}>
                        Please select a model from the list
                    </Typography>
                </Box>
            )}

            {/* Content when selected model is not null */}
            {selectedModel !== null && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        padding: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '1rem',
                    }}
                >
                    {/* Date */}
                    <InfoCard
                        icon={CalendarMonthIcon}
                        iconProps={{ fontSize: '4rem', color: theme.palette.primary[800]}}
                        title="Trained date"
                        subtitle= {new Date(selectedModel.performance.trainingDate).toLocaleDateString()}
                    />

                    {/* Time */}
                    <InfoCard
                        icon={ScheduleIcon}
                        iconProps={{ fontSize: '4rem', color: theme.palette.primary[800]}}
                        title="Trained time"
                        subtitle= {`${hours.toFixed(0)}h : ${minutes.toFixed(0)}m`}
                    />

                    {/* Sizes */}
                    <TrainSizesCard trainingSize={selectedModel.performance.trainingSize} validationSize={selectedModel.performance.validationSize} />

                    {/* Global Performance */}
                    <PerformanceCard performance={selectedModel.performance.globalPerformance} />

                </Box>
            )}

        </Paper>
    );
}

export default ModelGeneralPerformance