import { useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useDeleteSelectedImageMutation } from "state/api";
import { useDialog } from 'components/General/CustomDialog';

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to refresh the access tokens for the images in a project
function useDeleteSelectedImage(projectId, imageId, onComplete) {

    // Variables
    const token = useSelector(selectToken);
    const [deleteSelectedImage] = useDeleteSelectedImageMutation();
    const { requestConfirm } = useDialog();

    // Callback to start the refresh process
    const deleteImage = useCallback(async () => {
        // Early return if projectId is not available
        if (!projectId || !imageId) return;

        const confirmResult = await requestConfirm("Are you sure you want to delete this image?");
        if (!confirmResult) return;

        // Try to delete the image
        try {
            const response = await deleteSelectedImage({ token, requestBody: { imageId: imageId, projectId: projectId } }).unwrap();
            if (response.success) {
                onComplete(true);
            } else {
                onComplete(false)
            }
        } catch (error) {
            onComplete(false)
            console.error("Failed to delete image:", error);
        }

    }, [projectId, imageId]); // eslint-disable-line react-hooks/exhaustive-deps

    return { deleteImage};
}

export default useDeleteSelectedImage;