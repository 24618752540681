import React from 'react';
import { Paper, Box, useTheme, useMediaQuery } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween'; 
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const ProgressGraph = ({ stadistics, startDate, endDate, label }) => {
    const startDay = dayjs(startDate);
    const endDay = dayjs(endDate);

    // Filter annotations based on the label
    const relevantStadistics = label.name === 'All' ? stadistics : stadistics.filter(({ name }) => name === label.name);

    // Initialize each day in the range with a count of 0
    const dateCounts = {};
    for (let day = startDay; day.isSameOrBefore(endDay, 'day'); day = day.add(1, 'day')) {
        dateCounts[day.format('YYYY-MM-DD')] = 0;
    }

    // Increment the count for each relevant annotation's date
    relevantStadistics.forEach(({ createdAt }) => {
        const date = dayjs(createdAt).format('YYYY-MM-DD');
        if (dayjs(createdAt).isBetween(startDay, endDay, null, '[]')) {
            dateCounts[date] += 1;
        }
    });

    // Calculate cumulative counts
    let cumulativeCount = 0;
    const chartData = Object.entries(dateCounts).map(([date, count]) => {
        cumulativeCount += count;
        return { date, count: cumulativeCount };
    });

    return (
        <Paper elevation={3} sx={{ height: "99%", display: "flex", flexDirection: "column", p: '1rem 1rem 1.5rem 1rem' }}>
            <ResponsiveContainer width="100%" height='100%'>
                <LineChart
                    data={chartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(tickItem) => dayjs(tickItem).format('MMM DD')}
                        interval={'preserveStartEnd'}
                    />
                    <YAxis>
                        <Label 
                            value="Annotations Made"
                            angle={-90}
                            position="insideLeft"
                            style={{ textAnchor: 'middle' }}
                        />
                    </YAxis>
                    <Tooltip
                        labelFormatter={(label) => dayjs(label).format('YYYY-MM-DD')}
                    />
                    <Legend formatter={() => `Annotations for ${label.name}`} />
                    <Line
                        type="monotone"
                        dataKey="count"
                        name={`Annotations for ${label.name}`}
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default ProgressGraph;