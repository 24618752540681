import { useState, useEffect } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useGetProjectStadisticsMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to fetch the list of images availables in a project
function useFetchProjectStadistics(projectId, refresh) {

    // Variables
    const token = useSelector(selectToken);
    const [getProjectStadistics] = useGetProjectStadisticsMutation();
    const [data, setData] = useState([]);

    // Fetch the image list when the projectId is available (or changed)
    useEffect(() => {
        // Early return if projectId is not available
        if (!projectId) return;

        // Function to fetch the images list
        const fetchData = async () => {

            // If projectId is available and not ''
            try {
                const response = await getProjectStadistics({ token, requestBody: { projectId: projectId } }).unwrap();
                if (response.success) {
                    setData(response.data);
                } else {
                    setData([]);
                }
            } catch (error) {
                setData([]);
            }
        };

        fetchData();
    }, [projectId, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    return { data };
}

export default useFetchProjectStadistics;