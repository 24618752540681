import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, AppBar, Box, IconButton, Typography, Menu, MenuItem, Button, InputBase, Toolbar, useTheme } from '@mui/material';

// State
import { setLogout } from "state"

// Icons
import { Menu as MenuIcon } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import { ArrowDropDownOutlined } from '@mui/icons-material';

// Components
import FlexBetween from "components/General/FlexBetween"



// Hooks
import useRefreshProfileToken from 'components/Hooks/useRefreshProfileToken';

const Navbar = ({user, isSidebarOpen, setIsSidebarOpen}) => {
    // Dispatch and theme
    const dispatch = useDispatch();
    const theme = useTheme();

    // Get the user info from the state and build the full name
    user = useSelector((state) => state.persistedReducer.user);
    const { userProfilePicture } = useRefreshProfileToken();

    const fullName = user ? `${user.firstName} ${user.lastName}` : '';
    const email = user ? user.email : '';

    // Dropdown for the user profile
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (action) => {
        // Step 1. Close the dropdown
        setAnchorEl(null);

        // Step 2. Perform the action
        if (action === 'logout') {
            dispatch(setLogout());
        }
    };

    return (
        <AppBar sx={{position:"static", background:"none", boxShadow:"none"}}>
            <Toolbar sx={{justifyContent:"space-between", backgroundColor:theme.palette.primary[200]}}>
                {/* LEFT SIDE */}
                <FlexBetween>
                    {/* 1. Icon to close/open the sidebar */}
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)} sx={{mr:"0.5rem"}}>
                        <MenuIcon />
                    </IconButton>

                    {/* 2. Search bar */}
                    <FlexBetween backgroundColor={theme.palette.primary[100]} borderRadius="9px" gap="3rem" p="0.1rem 1.5rem">
                        <InputBase placeholder="Search" />
                        <IconButton>
                            <Search />
                        </IconButton>
                    </FlexBetween>
                </FlexBetween>

                {/* RIGHT SIDE */}
                <FlexBetween gap="1.5rem">
                    {/* 1. Light/Dark */}
                    {/* <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === "dark" ? <LightModeOutlined sx={{fontSize: "25px"}}/> : <DarkModeOutlined sx={{fontSize: "25px"}}/>}
                    </IconButton> */}

                    {/* 2. Settings */}
                    {/* <IconButton>
                        <SettingsOutlined sx={{fontSize: "25px", color: theme.palette.primary[900]}}/>
                    </IconButton> */}

                    {/* 3. Profile Image */}
                    <FlexBetween>
                        <Button
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                            }}
                        >
                            {/* Profile Image */}
                            <Box component="img" alt="profile" src={userProfilePicture} height="48px" width="48px" borderRadius="50%" sx={{ objectFit: "cover" }}/>

                            {/* Name and Occupation */}
                            <Box textAlign="left">
                                <Typography fontWeight="bold" fontSize="0.85rem" sx={{ color: theme.palette.primary[900] }}>
                                    {fullName}
                                </Typography>
                                <Typography fontSize="0.75rem" sx={{ color: theme.palette.primary[900] }}>
                                    {email}
                                </Typography>
                            </Box>

                            {/* Arrow to Close */}
                            <ArrowDropDownOutlined sx={{ color: theme.palette.primary[900], fontSize: "25px" }} />
                        </Button>

                        {/* Dropdown */}
                        {/* <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            <MenuItem onClick={handleClose}>Log Out</MenuItem>
                        </Menu> */}
                        <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 220,
                        color: 'text.secondary',
                        '& .MuiMenu-list': {
                            padding: '4px 0',
                        },
                        '& .MuiListItem-root': {
                            padding: '8px 16px',
                            '& .MuiListItemIcon-root': {
                                minWidth: 'auto',
                                marginRight: '8px',
                                '& svg': {
                                    fontSize: '20px',
                                },
                            },
                            '& .MuiListItemText-primary': {
                                fontSize: '14px',
                            },
                        },
                    },
                }}
            >
                <Box sx={{ padding: 2, borderBottom: '1px solid rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar src={userProfilePicture} sx={{ width: 128, height: 128 }} />
                    <Typography variant="subtitle1" sx={{ marginTop: 1 }}>{fullName}</Typography>
                    <Typography variant="body2" color="textSecondary">{email}</Typography>
                </Box>
                {/* List of actions */}
                <MenuItem onClick={() => handleClose('account')}>My Account</MenuItem>
                <MenuItem onClick={() => handleClose('settings')}>Settings</MenuItem>
                {/* ... other items */}
                <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
            </Menu>

                    </FlexBetween>

                </FlexBetween>

            </Toolbar>
        </AppBar>
    );
};

export default Navbar
