import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as VirtualizedList } from 'react-window';
import { Box, Paper, IconButton, Typography, Avatar, ListItemAvatar, useTheme } from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ImageIcon from '@mui/icons-material/Image';

// Custom Hooks
import useDeleteSelectedImage from "components/Hooks/useDeleteSelectedImage";


// Component to display a single image in the list
const ImageRow = ({ data, index, style }) => {
    const { imagesList, handleSelectedImage, handleDeleteImage, currentImage } = data;
    const image = imagesList[index];
    const theme = useTheme();

    return (
        <div style={style}>
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                p:1,
                pr: 2,
                pl: 1,
                width: '100%',
                backgroundColor: currentImage?.id === image.id ? 'action.selected' : '',
                '&:hover': {
                    backgroundColor: currentImage?.id === image.id ? 'action.selected' : 'action.hover',
                },
                cursor: 'pointer',
                }}
                onClick={() => handleSelectedImage(image)}
            >
                <ListItemAvatar>
                    <Avatar>
                    {!image.isAnnotated
                        ? <ImageIcon sx={{ color: theme.palette.failure[500] }} />
                        : <ImageIcon sx={{ color: theme.palette.success[500] }} />
                    }
                    </Avatar>
                </ListItemAvatar>

                <Typography sx={{ ml: 2, noWrap: true }}>
                    {image.name}
                </Typography>

                <IconButton
                    edge="end"
                    aria-label="delete"
                    disabled={currentImage?.id !== image.id}
                    onClick={(e) => handleDeleteImage(e, image)}
                    sx={{ ml: 'auto' }} // Move icon to the end
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
        </div>
    );
};

// Render when no images are available
const WaitingImagesPlaceHolder = () => {
    const theme = useTheme();

    return (
        <Paper
            elevation={3}
            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px'}}
        >
            {/* List of Images */}
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0 0',
                    padding: '1rem',
                    gap: "1rem",
                    backgroundColor: theme.palette.secondary[50],
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <ListAltIcon sx={{ color: theme.palette.secondary[800]}} />
                <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                    List of Images
                </Typography>
            </Paper>


            <Box sx={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '10px',
                    padding: '1rem',
                }}
            >

                <Typography variant="h6" color="text.secondary" sx={{ width: '100%', textAlign: 'center' }}>
                    No images to display
                </Typography>
            </Box>
        </Paper>
    );
}

// Component to display a list of images
const ProjectImagesList = ({ project, imagesList, newImageSelected }) => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(null);
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(300);

    // Update the container height when the images list changes
    useEffect(() => {
        // Function to update container height based on the current content
        const updateContainerHeight = () => {
          if (containerRef.current) {
            const newHeight = containerRef.current.offsetHeight; // or getBoundingClientRect().height
            setContainerHeight(newHeight);
          }
        };

        // Call it once immediately in case the images list has already changed
        updateContainerHeight();
      }, [imagesList]);

    // Callback to delete an image
    const { deleteImage } = useDeleteSelectedImage(project?.id, currentImage?.id, (result) => {
        if (result) {
            // Delete the current image from the list
            setCurrentImage(null);
            newImageSelected(null);
        }
    });

    // Function to handle the selection of an image
    const handleSelectedImage = (image) => {
        setCurrentImage(image);
        newImageSelected(image);
    }

    const handleDeleteImage = (e) => {
        e.stopPropagation();
        deleteImage();
    }

    // Case 1. No images available
    if (imagesList?.length === 0 || !imagesList) {
        return(<WaitingImagesPlaceHolder />);
    };

    // Case 2. Show the list of images
    return (
        <Paper
            elevation={3}
            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px'}}
        >
            {/* List of Images */}
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0 0',
                    padding: '1rem',
                    gap: "1rem",
                    backgroundColor: theme.palette.secondary[50],
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <ListAltIcon sx={{ color: theme.palette.secondary[800]}} />
                <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                    List of Images
                </Typography>
            </Paper>


            <Box ref={containerRef} sx={{width: '100%', height: '100%', overflowY: 'hidden'}}>

                <VirtualizedList
                    height={containerHeight}
                    width={'100%'}
                    itemCount={imagesList.length}
                    itemSize={57}
                    itemData={{ imagesList, handleSelectedImage, handleDeleteImage, currentImage }}
                >
                    {ImageRow}
                </VirtualizedList>
            </Box>
        </Paper>
    );
};


export default ProjectImagesList;