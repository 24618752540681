import { Box, Typography, useTheme, useMediaQuery, Button } from "@mui/material";
import { useState } from "react";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";

// Images
const logoWhite = `/assets/images/logo/logoWhite.svg`;
const loginImage = `/assets/images/loginImage/loginImage.jpg`;

// LoginPage component
const LoginPage = () => {
    const theme = useTheme();
    const [pageType, setPageType] = useState("login");
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));

    // Function to toggle form type
    const toggleFormType = () => setPageType(pageType === "login" ? "register" : "login");

    // Return the JSX for the LoginPage component
    return (
        // Main box to hold the entire page
        <Box minHeight="100vh" bgcolor={"#f5f5f5"}>

            {/* Top Bar */}
            <Box
                width="100%"
                bgcolor={theme.palette.primary.main}
                p={theme.spacing(3)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {/* Logo */}
                <Box display="flex" alignItems="center" height="100%" maxWidth="100%">
                    <img src={logoWhite} alt="Totia Logo" style={{ width: '100%', height: '100%'}} />
                </Box>

                <Button
                    variant="contained" // Changed to 'contained' for a solid background color
                    onClick={toggleFormType}
                    sx={{
                        backgroundColor: theme.palette.primary[600], // Set background color to primary color
                        textDecoration: 'none',
                        color: 'white',
                        padding: '10px 10px', // Adjust padding to make the button larger
                        fontSize: '1rem', // Adjust font size as needed
                        borderRadius: '10%', // Fully rounded edges
                        width: '8rem',
                        textTransform: 'none', // Prevent uppercase transformation
                        '&:hover': {
                            textDecoration: 'none',
                            backgroundColor: theme.palette.primary[800], // Darken button on hover
                        },
                    }}
                >
                    {pageType === "login" ? "Join Now" : "Log In"}
                </Button>
            </Box>

            {/* Main Content */}
            <Box
                display="grid"
                gridTemplateColumns={isNonMobileScreens ? '1fr 1fr' : '1fr'}
                gap={theme.spacing(4)}
                justifyContent="center"
                alignItems="center"
                pl = {theme.spacing(16)}
                pr = {theme.spacing(16)}
                pt = {theme.spacing(6)}
                pb = {theme.spacing(6)}
                width="100%"
                height="85vh" // Adjust the height as necessary

            >

                 {/* Form Section */}
                 <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box
                        bgcolor="white"
                        p={theme.spacing(8)}
                        borderRadius={theme.shape.borderRadius}
                        boxShadow={theme.shadows[5]}
                        m={isNonMobileScreens ? theme.spacing(2) : 'auto'}
                        textAlign="center"
                        justifyContent="center"
                        maxWidth={{ xs: '100%', md: '600px' }}
                        width="100%"
                    >
                        <Typography fontWeight="bold" variant="h1" mb={1} color={theme.palette.primary[600]}>
                            Welcome to Anotia
                        </Typography>

                        <Typography variant="h5" mb={8} color={theme.palette.grey[600]}>
                            Empower your diagnostic abilities
                        </Typography>

                        <LoginForm pageType={pageType} setPageType={setPageType}/>
                    </Box>
                </Box>

                {/* Illustration Section */}
                {isNonMobileScreens && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: theme.shadows[5],
                            '::before': {
                                content: '""',
                                display: 'block',
                                paddingTop: '56.25%', // 16:9 aspect ratio
                            },
                        }}
                    >
                        <img src={loginImage} alt="Anotia Login" style={{ width: '100%', height: '100%', objectFit: 'cover'}} />
                    </Box>
                )}

            </Box>

        </Box>
    );
};

export default LoginPage;
