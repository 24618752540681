import React from 'react';
import { Button, useTheme } from '@mui/material';

// Custom button component
const CustomButton = ({ text, onClick, sx, disabled=false }) => {
    const theme = useTheme();

    const defaultStyles = {
        backgroundColor: theme.palette.primary[600],
        color: 'white',
        padding: '10px 10px',
        fontSize: '1rem',
        borderRadius: '5%',
        width: '8rem',
        textTransform: 'none',
        '&:hover': {backgroundColor: theme.palette.primary[800]},
        ...sx,
    };

    return (
        <Button variant="contained" onClick={onClick} sx={defaultStyles} disabled={disabled}>
            {text}
        </Button>
    );
};

export default CustomButton;