import { useState } from 'react';
import { Box, Button, Chip, TextField, Typography, useTheme } from '@mui/material';
import { ChromePicker } from 'react-color';
import CancelIcon from '@mui/icons-material/Cancel';

const ProjectLabelsStep = ({ stepProps }) => {
    // Use theme
    const theme = useTheme();

    // Get the props
    const { projectLabels, setProjectLabels, isStepComplete, setIsStepComplete, title, subtitle } = stepProps;

    // Local State for input fields
    const [labelText, setLabelText] = useState('');
    const [labelColor, setLabelColor] = useState('#000000');

    // Function to calculate luminance
    const getLuminance = (color) => {
        const rgb = parseInt(color.substring(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    // Dynamic text color based on background color
    const dynamicTextColor = (color) => getLuminance(color) > 128 ? 'rgba(0, 0, 0, 0.87)' : '#ffffff';

    // Function to handle adding a label
    const handleAddLabel = () => {
        // Step 0. If label text is empty, return
        if (!labelText.trim()) return;

        // Step 1. The user cannot use same label text or color
        const isDuplicate = projectLabels.some((label) => label.text === labelText || label.color === labelColor);
        if (isDuplicate) return; // TODO: Show error message

        // Step 1. Add the label to the list of labels
        const newLabel = { text: labelText, color: labelColor };
        setProjectLabels([...projectLabels, newLabel]);

        // Step 2. Reset the input fields
        setLabelText('');
        setLabelColor('#000000');

        // Step 3. Set the step as complete
        setIsStepComplete(true);
    };

    // Function to handle deleting a label
    const handleDeleteLabel = (index) => {
        const newLabels = projectLabels.filter((_, i) => i !== index);
        setProjectLabels(newLabels);

        // If there are no labels, set the step as incomplete
        if (newLabels.length === 0) setIsStepComplete(false);
    };

    // Function to handle color change
    const handleChangeColor = (color) => {
        setLabelColor(color.hex);
    };

    return (

        // Main Box Area
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>

            {/* Title and subtitle */}
            <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{subtitle}</Typography>
            </Box>

            {/* 2 Columns: 1) Label, Color, Button; 2) Labels */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>

                {/* Input for label text, color and button */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>

                    {/* Label Text */}
                    <TextField fullWidth label="Label Text" variant="outlined" value={labelText} onChange={(e) => setLabelText(e.target.value)} sx={{ flex: 1 }}/>

                    {/* Color Picker */}
                    <Box sx={{ flex: 1, maxWidth: '300px' }}>
                        <ChromePicker color={labelColor} onChange={handleChangeColor} disableAlpha/>
                    </Box>

                    {/* Add Label Button */}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleAddLabel}
                        sx={{
                            backgroundColor: theme.palette.primary[600],
                            textDecoration: 'none',
                            color: 'white',
                            padding: '10px 20px',
                            fontSize: '1rem',
                            borderRadius: '2%',
                            textTransform: 'none',
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: theme.palette.primary[800],
                            },
                        }}
                    >
                        Add Label
                    </Button>

                </Box>

                {/* Labels */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px', // Adjusted for tighter spacing
                        p: 2,
                        border: '1px solid #e0e0e0',
                        backgroundColor: '#f5f5f5',
                        overflowY: 'auto',
                        width: '100%',
                        mt: 2,
                        borderRadius: '4px',
                        minWidth: '400px', // Match the minWidth of the left side
                        maxWidth: '400px', // Ensures the container does not exceed this width
                        minHeight: '375px', // Provides enough space for a decent number of chips
                    }}
                >
                    {/* Chip per label */}
                    {projectLabels.map((label, index) => (
                        <Chip
                            key={index}
                            label={label.text}
                            onDelete={() => handleDeleteLabel(index)}
                            deleteIcon={<CancelIcon style={{ color: dynamicTextColor(label.color) }} />}
                            sx={{
                                backgroundColor: label.color,
                                color: dynamicTextColor(label.color),
                                border: '1px solid #e0e0e0',
                                fontSize: '0.85rem', // Adjust if you want bigger text
                                margin: '4px', // Optional: Further control over individual chip spacing
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default ProjectLabelsStep;