import React, { useEffect, useState, useRef } from 'react';
import { Paper, Chip, Skeleton, TextField, Box, useTheme } from '@mui/material';
import { MapInteractionCSS } from 'react-map-interaction';

import CustomButton from 'components/General/CustomButton';

const defaultInteractionState = {
    scale: 1,
    translation: { x: 0, y: 0 },
};

const ImageViewer = ({ accessToken, tags, setTags, description, setDescription, displayName, setDisplayName }) => {

    // Variables
    const theme = useTheme();
    const [tagInput, setTagInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [interactionState, setInteractionState] = useState(defaultInteractionState);
    const imageRef = useRef(null);
    const mapContainerRef = useRef(null);

    // Effect to center the image when it loads
    useEffect(() => {
        const centerAndScaleImage = () => {
            if (imageRef.current && mapContainerRef.current) {
                const img = imageRef.current;
                const container = mapContainerRef.current;

                if (img.naturalWidth && img.naturalHeight) {
                    const containerRect = container.getBoundingClientRect();
                    const imgWidth = img.naturalWidth;
                    const imgHeight = img.naturalHeight;

                    // Calculate the best scale to fit the image in the container
                    const scaleX = containerRect.width / imgWidth;
                    const scaleY = containerRect.height / imgHeight;
                    const scale = Math.min(scaleX, scaleY);

                    // Adjust translations to center the image
                    const x = (containerRect.width / 2) - (imgWidth * scale / 2);
                    const y = (containerRect.height / 2) - (imgHeight * scale / 2);

                    setInteractionState({
                        scale: scale,
                        translation: { x, y },
                    });
                }
            }
        };

        // Call centerImage when the image has loaded
        window.addEventListener('img-loaded', centerAndScaleImage);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('img-loaded', centerAndScaleImage);
        };
    }, [accessToken, interactionState.scale]);

    // Reset the interaction state when the image changes
    useEffect(() => {
        setInteractionState(defaultInteractionState);
        setTagInput('');
    }, [accessToken]);

    // Function to add a tag
    const handleAddTag = () => {
        if (tagInput && !tags.includes(tagInput)) {
            setTags([...tags, tagInput]);
            setTagInput('');
        }
    };

    // Function to delete a tag
    const handleDeleteTag = (tagToDelete) => () => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    // If the image URL changes, set the loading state to true
    useEffect(() => {
        if (accessToken && accessToken !== '' && accessToken !== null) {
            setLoading(true);
        }
    }, [accessToken]);

    // Once the image is loaded, set the loading state to false
    const handleImageLoaded = () => {
        setLoading(false);
        const event = new Event('img-loaded');
        window.dispatchEvent(event);
    };


    // Return the component
    return (
        // Main container
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', gap: 1, borderRadius: '10px'}}>

            {/* Area 1. Image viewing area */}
            <Paper
                ref={mapContainerRef}
                elevation={3}
                sx={{ display: 'flex', flexGrow:5 , overflow: 'hidden', backgroundColor:theme.palette.secondary[100], mb:"1rem", border: "1px solid #ccc", borderRadius: '10px'}}>
                {accessToken
                ? ( <>
                    <MapInteractionCSS
                        showControls
                        value={interactionState}
                        defaultValue={interactionState}
                        onChange={setInteractionState}
                        minScale={0.1}
                        maxScale={10}
                    >
                        <img ref={imageRef} src={accessToken} alt="Selected" width={loading ? "0%" : "100%"} onLoad={handleImageLoaded} />
                    </MapInteractionCSS>
                </>) : (
                    <Skeleton variant="rectangular" />
                )}
            </Paper>

            {/* Area 2. Misc Information */}
            <Paper elevation={3} sx={{display: 'flex', flexDirection: 'row',  gap: 2, borderRadius: '10px', p:"1rem"}}>
                {accessToken
                ? (<>
                    {/* Area 2.1. Description */}
                    <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', flexGrow: 6, gap:'1rem'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <TextField label="Display Name" value={displayName} variant="outlined" fullWidth onChange={(e) => setDisplayName(e.target.value)} />
                        </Box>
                        <Box sx={{height: '100%', display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                            <TextField
                                label="Image Description"
                                multiline
                                value={description}
                                variant="outlined"
                                fullWidth
                                rows={4}
                                placeholder="Describe your project here..."
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Box>
                    </Box>

                    {/* Area 2.2. Tags */}
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '450px', border: "1px solid #ccc", padding: "1rem"}}>

                        {/* Row 1. Button and Input for the tags */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "1rem", mb:"1rem"}}>
                            <CustomButton text="Add" onClick={handleAddTag} sx={{padding: "5px 5px"}} />
                            <TextField value={tagInput} onChange={(e) => setTagInput(e.target.value)} variant="outlined" label="Add Tag" size='small' fullWidth/>
                        </Box>

                        {/* Row 2. List of tags */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                backgroundColor: theme.palette.secondary[10],
                                minHeight: 'calc(2 * 48px)',
                                maxHeight: 'calc(2 * 48px)',
                                overflowY: 'auto',
                                padding: '8px 8px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                borderRadius: '4px',
                                width: '100%',
                            }}>
                            {tags && tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                onDelete={handleDeleteTag(tag)}
                                color="info"
                            />
                        ))}
                        </Box>
                    </Box>

                </>) : (
                    <Skeleton variant="rectangular" height="200px"/>
                )}
            </Paper>

        </Box>
    );
};

export default ImageViewer;
