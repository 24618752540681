import React from 'react';
import { Paper, Box, Typography, useTheme } from '@mui/material';

// Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// Components
import CustomButton from 'components/General/CustomButton';

// API
import { useSelector } from "react-redux";

// Get the user from the store
const currentUser = state => state.persistedReducer.user;


// InfoCard component
const InfoCard = ({ icon: Icon, iconProps, title, subtitle }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{width: '100%', padding: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
            <Icon sx={{ color: theme.palette.secondary[800], fontSize: '4rem', ...iconProps }} />

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                <Typography variant="h4" color={theme.palette.secondary[500]}>
                    {title}
                </Typography>
                <Typography variant="h6" fontWeight='bold' color={theme.palette.primary[600]}>
                    {subtitle}
                </Typography>
            </Box>
        </Paper>
    );
};

// BasicUserInformation component
const BasicUserInformation = () => {
    const theme = useTheme();
    const user = useSelector(currentUser);

    return (
        <Paper
            elevation={3}
            sx={{
                height:'100%', display: 'flex', flexDirection: 'column',
                justifyContent:'center', alignItems: 'center', padding: '1rem', gap: '1rem'
            }}
        >
            <InfoCard
                icon={CalendarMonthIcon}
                iconProps={{ fontSize: '4rem', color: theme.palette.primary[800]}}
                title="Member since"
                subtitle= {new Date(user.createdAt).toLocaleDateString()}
            />

            <InfoCard
                icon={AssignmentIndIcon}
                iconProps={{ fontSize: '4rem', color: theme.palette.primary[800]}}
                title="Account Type"
                subtitle= {user.role === 'user' ? 'Basic' : 'Premium'}
            />

            <CustomButton
                text={'Upgrade Plan'}
                sx={{width: '13rem'}}
            />

        </Paper>
    );
};

export default BasicUserInformation;