import React, { useEffect, useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';

import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import ProjectSelector from 'components/General/ProjectSelector';
import ProgressConfiguration from 'components/Dashboards/ProgressConfiguration';
import ProgressGraph from 'components/Graphs/ProgressGraph';

import useFetchProjectStadistics from 'components/Hooks/useFetchProjectStadistics';

const Progress = () => {

    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));
    const [project, setProject] = useState(null);
    const { data: stadistics } = useFetchProjectStadistics(project?.id);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedLabel, setSelectedLabel] = useState('All');
    const [labels, setLabels] = useState([]);


    // On project change, update the selected model
    const handleNewProjectSelected = (project) => {
        setProject(project);

        const labelsList = project?.labels.map((label) => {
            return{
                id: label._id,
                name: label.text,
                color: label.color
            }
        });

        // Append at the beginning the 'All' label
        labelsList.unshift({id: 'All', name: 'All', color: 'grey'});
        setLabels(labelsList);
    }

    const handleSelectedLabel = (label) => {
        setSelectedLabel(label);
    }

    // Render the component
    return(
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 1. Header */}
            <FlexBetween>
                    <Header title="Progress" subtitle="Analyze how your project is growing" />
                    <ProjectSelector handleNewProjectSelected={handleNewProjectSelected} />
            </FlexBetween>

            {/* 2. Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.1. Graph Configuration */}
                    <Box gridColumn="span 12" gridRow="span 1" color={theme.palette.secondary[1000]}>
                        <ProgressConfiguration
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            stadistics={stadistics}
                            labels={labels}
                            handleSelectedLabel={handleSelectedLabel}
                        />
                    </Box>

                    {/* 2.2 Graph */}
                    <Box gridColumn="span 12" gridRow="span 9" color={theme.palette.secondary[1000]} >
                        <ProgressGraph stadistics={stadistics} startDate={startDate} endDate={endDate} label={selectedLabel} />
                    </Box>


                </Box>
            </Box>
        </Box>
    );
};

export default Progress;