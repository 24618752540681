import { useState, useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useRefreshImagesTokenMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to refresh the access tokens for the images in a project
function useRefreshImagesToken(projectId, images) {

    // Variables
    const token = useSelector(selectToken);
    const [refreshImagesToken] = useRefreshImagesTokenMutation();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const batchSize = 3;

    // Async function to update the tokens in controlled batches
    async function processInBatches(items, batchSize, processItem) {
        for (let i = 0; i < items.length; i += batchSize) {
            const batch = items.slice(i, i + batchSize);
            await Promise.all(batch.map(item => processItem(item)));
        }
    }

    // Callback to start the refresh process
    const refreshTokens = useCallback(async () => {
        // Early return if projectId is not available
        if (!projectId) return;

        // Set the loading state
        setLoading(true);
        setProgress(0);

        // If projectId is available and not ''
        await processInBatches(images, batchSize, async (image) => {
            try {
                const response = await refreshImagesToken({ token, requestBody: { imageId: image.id, projectId: projectId } }).unwrap();
                if (response.success) {
                    setProgress(prevProgress => prevProgress + 1);
                } else {
                    setError(response.error);
                }
            } catch (error) {
                setError(error);
                console.error("Failed to refresh token for image:", error);
            }
        });
        setLoading(false);

    }, [projectId, images]); // eslint-disable-line react-hooks/exhaustive-deps

    return { loading, error, progress, refreshTokens};
}

export default useRefreshImagesToken;