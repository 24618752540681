import React, { useEffect, useState } from 'react';
import { Paper, TextField, Button, Box, Avatar, Typography, useTheme, useMediaQuery } from '@mui/material';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Components
import CustomButton from 'components/General/CustomButton';
import UploadProfileModal from 'components/Forms/Modals/UploadProfileModal';

// API
import { useSelector } from "react-redux";
import useRefreshProfileToken from 'components/Hooks/useRefreshProfileToken';


// Get the user from the store
const currentUser = state => state.persistedReducer.user;

const UserProfilePicture = () => {
    const theme = useTheme();
    const user = useSelector(currentUser);
    const [openModal, setOpenModal] = useState(false);
    const { userProfilePicture } = useRefreshProfileToken();

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <Paper
            elevation={3}
            sx={{
                height:'100%', display: 'flex', flexDirection: 'column',
                justifyContent:'center', alignItems: 'center', padding: '1rem'
            }}
        >
            {/* Upload Profile Picture Modal */}
            <UploadProfileModal open={openModal} handleClose={handleClose} />

            {/* User Profile Picture or Default Icon */}
            {userProfilePicture === '' ? (
                <Avatar sx={{ bgcolor: theme.palette.secondary[200], width: 240, height: 240 }}>
                    <AccountCircleIcon sx={{ fontSize: 320 }} />
                </Avatar>
            ) : (
                <Avatar
                    src={userProfilePicture}
                    alt={`${user.firstName} ${user.lastName}`}
                    sx={{ width: 240, height: 240 }}
                />
            )}

            {/* User Name */}
            <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]} sx={{pt:"1rem"}}>
                {`${user.firstName} ${user.lastName}`}
            </Typography>

            {/* User Email */}
            <Typography variant="h6" color={theme.palette.secondary[500]} sx={{pb:"2rem"}}>
                {user.email}
            </Typography>

            {/* Edit Profile Button */}
            <CustomButton
                text={'Edit Profile Picture'}
                onClick={() => setOpenModal(true)}
                sx={{width: '13rem'}}
            />
        </Paper>
    );
};

export default UserProfilePicture;