import React, { useState } from 'react';
import { Box, Modal, Button, IconButton, Typography, Paper, LinearProgress, Avatar, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// API
import { useSelector } from "react-redux";
import useRefreshProfileToken from 'components/Hooks/useRefreshProfileToken';
import { useUploadProfileImageMutation } from 'state/api';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const UploadProfileModal = ({ open, handleClose }) => {
    const theme = useTheme();
    const { userProfilePicture, refreshProfile } = useRefreshProfileToken();
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState('');
    const [uploadProfileImage] = useUploadProfileImageMutation();
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp': ['.bmp'],
            'image/gif': ['.gif'],
            'image/webp': ['.webp'],
            'image/tiff': ['.tiff', '.tif']
        },
        multiple: false,
        onDrop: (acceptedFiles) => {
            const newFile = acceptedFiles[0] ? {
                file: acceptedFiles[0],
                preview: URL.createObjectURL(acceptedFiles[0]),
            } : null;
            setFile(newFile);
        },
    });

    const startUpload = async () => {
        if (!file) return;

        const additionalInfo = {
            userEmail: user.email
        };

        setUploadProgress('uploading');

        try {
            const response = await uploadProfileImage({ token, file: file.file, additionalInfo }).unwrap();
            if(response.success)
            {
                setUploadProgress('success');
                refreshProfile ();
            }
        } catch (error) {
            setUploadProgress('error');
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => { handleClose(); setFile(null); setUploadProgress(''); }}
            aria-labelledby="upload-image-modal-title"
            aria-describedby="upload-image-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="upload-image-modal-title" variant="h6" component="h2">
                    Upload Profile Image
                </Typography>
                <Paper {...getRootProps()} sx={{
                    mt: 2, p: 2, border: '2px dashed',
                    borderColor: isDragActive ? theme.palette.primary.main : '#eeeeee',
                    bgcolor: isDragActive ? theme.palette.action.hover : '#ffffff',
                    color: theme.palette.text.secondary, textAlign: 'center',
                    cursor: 'pointer', height: '150px', display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                }}>
                    <input {...getInputProps()} />
                    <Typography>
                        {isDragActive ? 'Drop the file here ...' : 'Drag \'n\' drop a file here, or click to select a file'}
                    </Typography>
                </Paper>
                {file && (
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Avatar src={file.preview} sx={{ width: 56, height: 56 }} />
                        <Typography sx={{ mx: 2 }}>{file.file.name}</Typography>
                        {uploadProgress === 'uploading' && <Typography>Uploading...</Typography>}
                        {uploadProgress === 'success' && <CheckCircleIcon color="success" />}
                        {uploadProgress === 'error' && <ErrorIcon color="error" />}
                        <IconButton onClick={() => setFile(null)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )}
                <LinearProgress variant={uploadProgress === 'uploading' ? "indeterminate" : "determinate"} value={uploadProgress === 'success' ? 100 : 0} sx={{ width: '100%', mt: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={() => { handleClose(); setFile(null); setUploadProgress(''); }}>Close</Button>
                    <Button variant="contained" onClick={startUpload} disabled={!file}>Start Upload</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UploadProfileModal;
