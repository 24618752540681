import React, { createContext, useContext, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// Create Context
const DialogContext = createContext();

// Custom Hook to use context
export const useDialog = () => useContext(DialogContext);

// Custom Dialog Component
const CustomDialog = ({ open, alertText, onConfirm, onCancel }) => (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>

        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {alertText}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm} autoFocus>Confirm</Button>
        </DialogActions>
    </Dialog>
);

// Context Provider Component
export const DialogProvider = ({ children }) => {
    const [dialogProps, setDialogProps] = useState({
        open: false,
        alertText: '',
        onConfirm: () => {},
        onCancel: () => {},
    });

    const requestConfirm = useCallback((alertText) => {
        return new Promise((resolve, reject) => {
            setDialogProps({
                open: true,
                alertText,
                onConfirm: () => {
                    resolve(true); // Resolve the promise when confirmed
                    setDialogProps((prev) => ({ ...prev, open: false }));
                },
                onCancel: () => {
                    resolve(false); // Resolve the promise as false
                    setDialogProps((prev) => ({ ...prev, open: false }));
                },
            });
        });
    }, []);

    return (
        <DialogContext.Provider value={{ requestConfirm }}>
            {children}
            <CustomDialog {...dialogProps} />
        </DialogContext.Provider>
    );
};