import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


// ItemSelector component - Select an item from a list and trigger a function when the user selects an item
const ItemSelector = ({ items, selectionChanged, label = "Item", setFirstElement=false }) => {

    // Variables
    const [selectedItem, setSelectedItem] = useState('');
    const labelId = label.toLowerCase().replace(/\s+/g, '-');

    // Function to handle the selection change
    const handleSelectionChange = (e) => {
        // Set the selected item and trigger the function pass by the parent component
        setSelectedItem(e.target.value);
        selectionChanged(items.find(item => item.id === e.target.value));
    }

    useEffect(() => {
        if (setFirstElement && items.length > 0) {
            const firstItemId = items[0].id;
            setSelectedItem(firstItemId);
            selectionChanged(items[0]);
        }
    }, [items, setFirstElement]); // eslint-disable-line

    return (
        <FormControl variant="outlined" sx={{ width: '100%' }}>
            <InputLabel id={`${labelId}-selector-label`}>{label}</InputLabel>
            <Select
                labelId={`${labelId}-selector-label`}
                id={`${labelId}-selector`}
                value={selectedItem}
                onChange={handleSelectionChange}
                label={label}
            >
                {items.lenght === 0 ? (
                    <MenuItem disabled value="">
                        No items found
                    </MenuItem>
                ) : (
                    items.map((item) => (
                        // Use item.id as the value for each option, and item.name for what the user sees
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
    );
};

export default ItemSelector;
