import React, { useState, useEffect } from 'react';
import { Box, Paper, TextField, Chip, List, ListItem, ListItemText, IconButton, Typography, Avatar, ListItemAvatar, ListItemButton, useTheme } from '@mui/material';

import CustomButton from 'components/General/CustomButton';

const ImageAnnotationInfo = ({ annotations, setAnnotations, selectedAnnotation }) => {
    // Get the theme
    const theme = useTheme();

    // States to keep track of the internal states
    const [tagInput, setTagInput] = useState('');
    const [annotationNotes, setAnnotationNotes] = useState('');
    const [annotationTags, setAnnotationTags] = useState([]);
    const [label, setLabel] = useState('');
    const [ createdAt, setCreatedAt] = useState('');

    // If the selected annotation changes, update the internal states
    useEffect(() => {
        if (selectedAnnotation !== '' && selectedAnnotation !== null && selectedAnnotation !== undefined) {
            // Find the annotation that matches the selectedAnnotationId
            const selectedAnnotationElement = annotations.find((annotation) => annotation.id === selectedAnnotation);

            // Update the internal states
            setAnnotationNotes(selectedAnnotationElement.notes);
            setAnnotationTags(selectedAnnotationElement.tags);
            setLabel(selectedAnnotationElement.text);
            setCreatedAt(selectedAnnotationElement.createdAt);
        }
    }, [selectedAnnotation]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAnnotationNotesChange = (e) => {
        // Step 1. Update the internal state
        setAnnotationNotes(e.target.value);

        // Step 2. Find the index of the selected annotation on the currentImage.annotations array
        const annotationIndex = annotations.findIndex((annotation) => annotation.id === selectedAnnotation);

        // Update the notes of the selected annotation
        setAnnotations([
            ...annotations.slice(0, annotationIndex),
            {
                ...annotations[annotationIndex],
                notes: e.target.value,
            },
            ...annotations.slice(annotationIndex + 1),
        ]);
    };

    const handleAnnotationTagChange = () => {
        if (tagInput && !annotationTags.includes(tagInput)) {
            // Step 1. Update the internal state
            setAnnotationTags([...annotationTags, tagInput]);

            // Step 2. Find the index of the selected annotation on the currentImage.annotations array
            const annotationIndex = annotations.findIndex((annotation) => annotation.id === selectedAnnotation);

            // Update the tags of the selected annotation
            setAnnotations([
                ...annotations.slice(0, annotationIndex),
                {
                    ...annotations[annotationIndex],
                    tags: [...annotations[annotationIndex].tags, tagInput],
                },
                ...annotations.slice(annotationIndex + 1),
            ]);

            // Step 3. Clear the input
            setTagInput('');
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        // Step 1. Update the internal state
        setAnnotationTags(annotationTags.filter((tag) => tag !== tagToDelete));

        // Step 2. Find the index of the selected annotation on the currentImage.annotations array
        const annotationIndex = annotations.findIndex((annotation) => annotation.id === selectedAnnotation);

        // Step 3. Update the tags of the selected annotation
        setAnnotations([
            ...annotations.slice(0, annotationIndex),
            {
                ...annotations[annotationIndex],
                tags: annotations[annotationIndex].tags.filter((tag) => tag !== tagToDelete),
            },
            ...annotations.slice(annotationIndex + 1),
        ]);
    };

    // If annotation is not selected, return an empty box
    if (!selectedAnnotation) {
        return(
            <Paper
                elevation={3}
                sx={{height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '10px'}}
            >
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignContent:'center' , height:'100%' , flexGrow: 1, p: '1rem', m: 0}}>
                    <Typography variant="body1" sx={{color: theme.palette.primary[700]}}>
                        Select an annotation to see its details
                    </Typography>
                </Box>
            </Paper>
        );
    }

    return(
        <Paper
            elevation={3}
            sx={{height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '10px'}}
        >
            {/* Box 1. Notes */}
            <Box sx={{display: 'flex', flexDirection: 'row', flexGrow: 1, p: '1rem', m: 0}}>
                <TextField
                    label="Project Description"
                    multiline
                    value={annotationNotes}
                    variant="outlined"
                    fullWidth
                    placeholder="Describe your project here..."
                    onChange={handleAnnotationNotesChange}
                    InputProps={{
                        style: { height: '100%' },
                        inputProps: { style: { height: '100%' } },
                    }}
                />
            </Box>

            {/* Box 2. Tags */}
            <Box sx={{display: 'flex', flexDirection: 'column', p: "1rem"}}>

                {/* Row 1. Button and Input for the tags */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: "1rem", mb:"1rem"}}>
                    <CustomButton text="Add" onClick={handleAnnotationTagChange} sx={{padding: "5px 5px"}} />
                    <TextField value={tagInput} onChange={(e) => setTagInput(e.target.value)} variant="outlined" label="Add Tag" size='small' fullWidth/>
                </Box>

                {/* Row 2. List of tags */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                        backgroundColor: theme.palette.secondary[10],
                        minHeight: 'calc(2 * 48px)',
                        maxHeight: 'calc(2 * 48px)',
                        overflowY: 'auto',
                        padding: '8px 8px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '4px',
                        width: '100%',
                    }}>
                    {annotationTags && annotationTags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={handleDeleteTag(tag)}
                        color="info"
                    />
                ))}
                </Box>
            </Box>

            {/* Box 3. Author and date */}
            <Box
                sx={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'left', padding: '1rem'}}
            >
                <Typography variant="body1">
                    <span style={{ fontSize: '1rem', fontWeight: 'bold', color: theme.palette.primary[700]}}>Label:</span>
                    <span style={{ marginLeft: 8, fontSize: '1rem' }}>
                        {label}
                    </span>
                </Typography>

                <Typography variant="body1">
                    <span style={{ fontSize: '1rem', fontWeight: 'bold', color: theme.palette.primary[700] }}>Date:</span>
                    <span style={{ marginLeft: 8, fontSize: '1rem' }}>
                        {new Date(createdAt).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}
                    </span>
                </Typography>

            </Box>


        </Paper>
    );
}

export default ImageAnnotationInfo;