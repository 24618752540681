import { Box, Chip, TextField, Typography, Autocomplete } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const ProjectCollaboratorsStep = ({ stepProps }) => {

    // Assuming userCollaborators is an array of objects with each having an email and possibly a name
    const { projectCollaborators, setProjectCollaborators, userCollaborators, title, subtitle } = stepProps;


    // Handler for changes in selection
    const handleSelectionChange = (event, newValue) => {
        // Add the latest selected value if it's not already selected
        const latestValue = newValue[newValue.length - 1];
        if (!projectCollaborators.includes(latestValue)) {
            setProjectCollaborators([...projectCollaborators, latestValue].filter(Boolean));
        }
    };

    // Handler for deleting a chip
    const handleDeleteChip = (chipToDelete) => {
        setProjectCollaborators(projectCollaborators.filter(option => option !== chipToDelete));
    };

    return (

        // Main Box Area
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>

            {/* Title and subtitle */}
            <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{subtitle}</Typography>
            </Box>


            {/* Collaborator Input and Chips */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5, mt:"1rem" }}>
                {/* Collaborator Email Input */}
                <Autocomplete
                    multiple
                    id="emailAutocomplete"
                    options={userCollaborators.filter(option => !projectCollaborators.includes(option))}
                    getOptionLabel={(option) => option}
                    onChange={handleSelectionChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Collaborators"
                            placeholder="Select Collaborator"
                            sx={{
                                width: '500px',
                                '& .MuiOutlinedInput-root': {
                                    height: '56px',
                                    '&.Mui-focused fieldset': {borderColor: 'primary.main'},
                                    '& input': {fontSize: '1rem'},
                                    '& fieldset': {borderWidth: '2px'},
                                    '&.Mui-focused input': {fontWeight: 'bold'}
                                },
                                '& .MuiInputLabel-root': {fontSize: '1rem'},
                            }}
                        />
                    )}
                    renderTags={() => null}
                    value={[]}
                />
            </Box>

            {/* Collaborators Chips */}
            <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>Current Collaborators:</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                    p: 2,
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#f5f5f5',
                    overflowY: 'auto',
                    width: '100%',
                    mt: 2,
                    borderRadius: '4px',
                }}
            >
                {projectCollaborators.map((email, index) => (
                    <Chip
                        key={index}
                        label={email}
                        onDelete={() => handleDeleteChip(email)}
                        deleteIcon={<CancelIcon />}
                        sx={{
                            fontSize: '0.85rem',
                            margin: '4px',
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ProjectCollaboratorsStep;
