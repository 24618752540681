import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Components
import Navbar from "components/General/Navbar"
import Sidebar from "components/General/Sidebar"

const Layout = () => {
    // Check if we are on mobile or not
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const user = useSelector((state) => state.persistedReducer.user);

    return (
        <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
            {/* Sidebar */}
            <Sidebar
                user={user || {}}
                isNonMobile={isNonMobile}
                drawerWidth="250px"
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
            />
            <Box flexGrow={1}>
                <Navbar
                    user={user || {}}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout
