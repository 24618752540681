import { useState, useEffect, useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useGetAnnotationsInformationMutation, useUpdateAnnotationsInformationMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

const areAnnotationsEqual = (annotations, modifiedAnnotations) => {
    // Check if both arrays have the same length
    if (annotations.length !== modifiedAnnotations.length) {
      return false;
    }

    for (let i = 0; i < annotations.length; i++) {
      const original = annotations[i];
      const modified = modifiedAnnotations[i];

      // Compare each property.
      const isEqual = original.id === modified.id &&
                      original.text === modified.text &&
                      original.stroke === modified.stroke &&
                      original.strokeWidth === modified.strokeWidth &&
                      original.fill === modified.fill &&
                      original.points.length === modified.points.length &&
                      original.notes === modified.notes &&
                      original.points.every((point, index) => point === modified.points[index]) &&
                      original.tags.length === modified.tags.length &&
                      original.tags.every((tag, index) => tag === modified.tags[index]) &&
                      original.createdAt === modified.createdAt &&
                      original.author === modified.author;

      if (!isEqual) {
        return false;
      }
    }

    // If all objects in the arrays are equal, return true
    return true;
};

// Custom hook to fetch and update the image information
function useAnnotationsInformation(projectId, imageId, onAnnotationsUpdate) {

    // Variables
    const token = useSelector(selectToken);
    const [annotations, setAnnotations] = useState([]);

    // State to store fetched information for comparison
    const [fetchedInfo, setFetchedInfo] = useState({ projectId: '', imageId: '', tags: [], description: '', displayName: '' });

    const [getAnnotationsInformation] = useGetAnnotationsInformationMutation();
    const [updateAnnotationsInformation] = useUpdateAnnotationsInformationMutation();

    // Function to fetch annotations information from the API
    const fetchInformation = useCallback(async () => {
        try {
            const response = await getAnnotationsInformation({ token, requestBody: { projectId, imageId } }).unwrap();
            if (response.success) {
                setAnnotations(response.annotations);
                setFetchedInfo({ projectId, imageId, annotations: response.annotations});
            } else {
                console.error('Failed to fetch annotations information:', response.message);
                setAnnotations([]);
                setFetchedInfo({ projectId: '', imageId: '', annotations: [] });
            }
        } catch (error) {
            console.error('Failed to fetch annotations information:', error);
            setAnnotations([]);
            setFetchedInfo({ projectId: '', imageId: '', annotations: [] });
        }
    }, [projectId, imageId, token]); // eslint-disable-line react-hooks/exhaustive-deps


    // Function to update annotations information through the API
    const updateInformation = useCallback(async (requestProjectId, requestImageId) => {
        try {
            const response = await updateAnnotationsInformation({ token, requestBody: { projectId: requestProjectId, imageId: requestImageId, annotations } }).unwrap();
            if (response.success) {
            } else {
                console.error('Failed to update annotations information:', response.message);
            }
        } catch (error) {
            console.error('Failed to update annotations information:', error);
        }
    }, [projectId, imageId, annotations, token]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to fetch annotations information when projectId, imageId, or token changes
    useEffect(() => {
        if (projectId && imageId) {
            // If the projectId and imageId doesnt match the fetched information, update first before fetching new data
            if ((projectId !== fetchedInfo.projectId || imageId !== fetchedInfo.imageId) && (fetchedInfo.projectId !== '' || fetchedInfo.imageId !== '')) {
                // Check if there are actual changes to update on tags, description, or displayName
                if (!areAnnotationsEqual(annotations, fetchedInfo.annotations)) {
                    updateInformation(fetchedInfo.projectId, fetchedInfo.imageId);
                    
                    // Call the onAnnotationsUpdate function if before there where 0 annotations and now is 1 or more or vice versa
                    if (annotations.length === 0 && fetchedInfo.annotations.length > 0) {
                        onAnnotationsUpdate();
                    } else if (annotations.length > 0 && fetchedInfo.annotations.length === 0) {
                        onAnnotationsUpdate();
                    }
                }
            }
            fetchInformation();
        }
    }, [projectId, imageId, fetchInformation]); // eslint-disable-line react-hooks/exhaustive-deps


    // Function to clear the information when the user delete an image
    const forceClear = () => {
        setAnnotations([]);
        setFetchedInfo({ projectId: '', imageId: '', annotations: [] });
    }

    // Function to force an update if the user clicks on the same image
    const forceUpdate = () => {
        updateInformation(fetchedInfo.projectId, fetchedInfo.imageId);
    }

    return { annotations, setAnnotations, forceUpdate, forceClear };
}

export default useAnnotationsInformation;