import React, {useState, useEffect} from 'react';
import { Paper, Box, Typography, useTheme, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

// Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// Components
import CustomButton from 'components/General/CustomButton';

// API
import { useSelector } from "react-redux";

// Hooks
import useFetchUserStadistics from 'components/Hooks/useFetchUserStadistics';


// InfoCard component
const InfoCard = ({ title, subtitle }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{width: '100%', padding: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"center"}}>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={'0.75rem'}>
                <Typography variant="h4" color={theme.palette.secondary[500]}>
                    {title}
                </Typography>
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {subtitle}
                </Typography>
            </Box>
        </Paper>
    );
};


// CarouselItem
const CarouselItem = ({ item }) => {
    const theme = useTheme();

    const handleDeleteProject = (projectId) => {
        
    }

    return (
        <Paper
            elevation={3}
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                border: '1px solid #e0e0e0',
                gap: '1.5rem',
                padding: '1rem',
            }}
        >
            <Typography variant="h2" color={theme.palette.secondary[600]} fontWeight="bold">
                {item.projectName}
            </Typography>

            <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="space-between" gap="1rem">
                <InfoCard
                    title="Images Total"
                    subtitle= {item.totalImages}
                />

                <InfoCard
                    title="Images Annotated"
                    subtitle= {item.annotatedImages}
                />

                <InfoCard
                    title="Labels"
                    subtitle= {item.totalLabels}
                />
            </Box>

            <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="space-between" gap="1rem">
                <InfoCard
                    title="Role"
                    subtitle= {item.role}
                />

                <InfoCard
                    title="Creation Date"
                    subtitle={new Date(item.creationDate).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                    })}
                />
            </Box>


            <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">
                <CustomButton
                    text={'Delete Project'}
                    onClick={() => handleDeleteProject(item.projectId)}
                    sx={{ width: '13rem', backgroundColor: theme.palette.failure[500], '&:hover': {backgroundColor: theme.palette.failure[700]}, }}
                />
            </Box>




        </Paper>
    );
};

// BasicUserInformation component
const AdvanceUserInformation = () => {
    const theme = useTheme();
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [items, setItems] = useState([]);
    const { data: userStadistics } = useFetchUserStadistics();
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    useEffect(() => {
        if (userStadistics.length > 0) {
            setItems(userStadistics);
        }
    }, [userStadistics]);

    return (
        <Paper
            elevation={3}
            sx={{
                height:'100%', display: 'flex', flexDirection: 'column',
                justifyContent:'space-between', alignItems: 'center', padding: '1rem', gap: '1rem'
            }}
        >
            <Box width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" p="1rem">
                <Carousel autoPlay={false} sx={{ width: '100%', '.carouselItem': { width: '100%' } }} onChange={(now, previous) => setActiveItemIndex(now)}>
                    {items.map((item, i) => (
                        <CarouselItem sx={{ width: '100%' }} key={i} item={item} />
                    ))}
                </Carousel>
            </Box>

            <Box width='100%' height='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" p="1rem">
                <TextField fullWidth id='userInfo' label='In Progress' multiline rows={10} value={'This functionality is not available yet'} disabled />
            </Box>

        </Paper>
    );
};

export default AdvanceUserInformation;