import React from "react";
import FlexBetween from "components/General/FlexBetween";
import Header from "components/General/Header";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";

const Dashboard = () => {
  const theme = useTheme();

  return (
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 1. Header */}
            <FlexBetween>
                <Header title="Dashboard" subtitle="Welcome back to Anotia" />
            </FlexBetween>

            <Box display="flex" flexGrow={1} width='100%'>

                {/* 2. Main Content */}
                <Box mt="20px" width='100%' display="grid" gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(8, 1fr)" gap="20px">

                    <Box gridColumn="span 3" gridRow="span 8" color={theme.palette.secondary[1000]}>
                    </Box>

                </Box>

            </Box>
        </Box>
    );
};

export default Dashboard;