import React, { useState, useEffect } from 'react';
import { Paper, Grid, Button, Box, Skeleton, useTheme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Carousel from 'react-material-ui-carousel'

// Define a static color map for the categories
const CATEGORY_COLORS = {
    'Correct': '#4caf50', // Green
    'Miss Classified': '#f44336', // Red
    'Not Detected': '#ff9800', // Orange
    'False Positive': '#2196f3' // Blue for False Positive
};

const SquareOverlap = ({ iou }) => {
    const size = 100; // Size of each square
    const maxOffset = Math.sqrt(2) * size; // Maximum diagonal distance for no overlap
    const offset = (1 - iou) * maxOffset / Math.sqrt(2); // Adjusted offset for IoU

    // Calculate the minimum SVG size required to fit both squares
    // We need to consider the maximum extent which can be when one square is diagonally offset from the other
    const svgWidth = size + offset;
    const svgHeight = size + offset;

    // Calculate starting positions for the squares
    const startX = 0; // The first square can start at 0 since we're adjusting the SVG size dynamically
    const startY = 0; // Similarly, startY can be 0

    return (
        <svg width={svgWidth} height={svgHeight} style={{ border: 'none' }}>
            {/* First square */}
            <rect x={startX} y={startY} width={size} height={size} style={{ fill: 'red', opacity: 0.7, stroke: 'black', strokeWidth: 2 }} />

            {/* Second square */}
            <rect x={startX + offset} y={startY + offset} width={size} height={size} style={{ fill: 'blue', opacity: 0.7, stroke: 'black', strokeWidth: 2 }}/>
        </svg>
    );
};

// CarouselItem
const CarouselItem = ({ item }) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={3}
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                border: '1px solid #e0e0e0',
                gap: '1rem',
                padding: '1rem',
            }}
        >
            <Typography variant="h2" color={theme.palette.secondary[600]} fontWeight="bold">
                {item.name}
            </Typography>

            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="1rem">

                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">

                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        {/* Row 1, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" fontWeight='bold' color={theme.palette.primary[600]}>
                                BBox Overlap
                            </Typography>
                        </Grid>
                        {/* Row 1, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" fontWeight='bold' color={theme.palette.primary[600]}>
                                Mask Overlap
                            </Typography>
                        </Grid>
                        {/* Row 2, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <SquareOverlap iou={item.trainingCorrectOverlappingBbx/100} />
                        </Grid>
                        {/* Row 2, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <SquareOverlap iou={item.trainingCorrectOverlappingMasks/100} />
                        </Grid>
                        {/* Row 3, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                                {`${(item.trainingCorrectOverlappingBbx).toFixed(2)}%`}
                            </Typography>
                        </Grid>
                        {/* Row 3, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                                {`${(item.trainingCorrectOverlappingMasks).toFixed(2)}%`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="h4" fontWeight='bold' color={theme.palette.secondary[500]}>
                        Training
                    </Typography>

                </Box>


                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">

                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        {/* Row 1, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" fontWeight='bold' color={theme.palette.primary[600]}>
                                BBox Overlap
                            </Typography>
                        </Grid>
                        {/* Row 1, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" fontWeight='bold' color={theme.palette.primary[600]}>
                                Mask Overlap
                            </Typography>
                        </Grid>
                        {/* Row 2, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <SquareOverlap iou={item.validationCorrectOverlappingBbx/100} />
                        </Grid>
                        {/* Row 2, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <SquareOverlap iou={item.validationCorrectOverlappingMasks/100} />
                        </Grid>
                        {/* Row 3, Column 1 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                                {`${(item.validationCorrectOverlappingBbx).toFixed(2)}%`}
                            </Typography>
                        </Grid>
                        {/* Row 3, Column 2 */}
                        <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                                {`${(item.validationCorrectOverlappingMasks).toFixed(2)}%`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="h4" fontWeight='bold' color={theme.palette.secondary[500]}>
                        Validation
                    </Typography>

                </Box>

            </Box>

        </Paper>
    );
};


const LabelGraph = ({ item }) => {
    const theme = useTheme();
    const [view, setView] = useState('training'); // 'training' or 'validation'
    const [data, setData] = useState([]);

    // Adjust the data structure to fit your needs
    useEffect(() => {
        // Check if item is undefined or null before proceeding
        if (!item || Object.keys(item).length === 0) {
            setData([]);
            return;
        }

        const inputData = [
            {
                name: 'Correct',
                value: view === 'training' ? item.trainingCorrect : item.validationCorrect,
                color: CATEGORY_COLORS['Correct'],
            },
            {
                name: 'Miss Classified',
                value: view === 'training' ? item.trainingMissclassified : item.validationMissclassified,
                color: CATEGORY_COLORS['Miss Classified'],
            },
            {
                name: 'Non Detected',
                value: view === 'training' ? item.trainingNonDetected : item.validationNonDetected,
                color: CATEGORY_COLORS['Non Detected'],
            },
            {
                name: 'False Positive',
                value: view === 'training' ? item.trainingFalsePositive : item.validationFalsePositive,
                color: CATEGORY_COLORS['False Positive'],
            },
        ];
        setData(inputData);
    }, [item]); // Update the data when the item or view changes

    // If data is not available, return a skeleton
    if (data.length === 0) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    return (
        <Paper
            elevation={3}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                border: '1px solid #e0e0e0',
                gap: '1rem',
                padding: '1rem',
            }}
        >
            <ToggleButtonGroup color="primary" value={view} exclusive onChange={(_, newView) => newView && setView(newView)}>
                <ToggleButton value="training">Training</ToggleButton>
                <ToggleButton value="validation">Validation</ToggleButton>
            </ToggleButtonGroup>

            <ResponsiveContainer width="100%">
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8">
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
};


const ModelInformation = ({ selectedModel }) => {
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    useEffect(() => {
        // If selectedModel is not available, return and set items to empty
        if (!selectedModel) {
            setItems([]);
            return;
        }

        // Get the validation overall results per label
        const valItems = Object.keys(selectedModel.performance.resultsPerClassVal).map(key => {
            const label = selectedModel.performance.resultsPerClassVal[key];
            return {
                name: key, // The key is the label name
                correctOverlappingBbx: label.correctOverlappingBbx,
                correctOverlappingMasks: label.correctOverlappingMasks,
                falsePositive: label.falsePositive,
                correct: label.correct,
                nonDetected: label.nonDetected,
                missclassified: 100 - label.correct - label.nonDetected,
            };
        });

        // Get the training overall results per label
        const trainItems = Object.keys(selectedModel.performance.resultsPerClassTrain).map(key => {
            const label = selectedModel.performance.resultsPerClassTrain[key];
            return {
                name: key, // The key is the label name
                correctOverlappingBbx: label.correctOverlappingBbx,
                correctOverlappingMasks: label.correctOverlappingMasks,
                falsePositive: label.falsePositive,
                correct: label.correct,
                nonDetected: label.nonDetected,
                missclassified: 100 - label.correct - label.nonDetected,
            };
        });

        // Combine the two sets into one renaming the keys
        const combinedItems = trainItems.map((item, i) => {
            return {
                name: item.name,
                trainingCorrectOverlappingBbx: item.correctOverlappingBbx,
                trainingCorrectOverlappingMasks: item.correctOverlappingMasks,
                trainingFalsePositive: item.falsePositive,
                trainingCorrect: item.correct,
                trainingNonDetected: item.nonDetected,
                trainingMissclassified: item.missclassified,
                validationCorrectOverlappingBbx: valItems[i].correctOverlappingBbx,
                validationCorrectOverlappingMasks: valItems[i].correctOverlappingMasks,
                validationFalsePositive: valItems[i].falsePositive,
                validationCorrect: valItems[i].correct,
                validationNonDetected: valItems[i].nonDetected,
                validationMissclassified: valItems[i].missclassified,
            };
        })

        // Set the items
        setItems(combinedItems);

    }, [selectedModel]);



    // If the model is not available, return a skeleton
    if (!selectedModel) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    return (
        <Paper elevation={3} sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px'}}>

            <Box width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" p="1rem">
                <Carousel autoPlay={false} sx={{ width: '100%', '.carouselItem': { width: '100%' } }} onChange={(now, previous) => setActiveItemIndex(now)}>
                    {items.map((item, i) => (
                        <CarouselItem sx={{ width: '100%' }} key={i} item={item} />
                    ))}
                </Carousel>
            </Box>

            <Box height='100%' width='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" p="1rem" >
                <LabelGraph item={items ? items[activeItemIndex] : {}} />
            </Box>

        </Paper>
    );

};

export default ModelInformation;