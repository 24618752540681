import React, { useState, useCallback } from 'react';
import { FixedSizeList as VirtualizedList } from 'react-window';
import { Box, Paper, Slider, Button, List, ListItem, ListItemText, Typography, ListItemButton, useTheme } from '@mui/material';

import CustomButton from 'components/General/CustomButton';

import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


// Individual row for the virtualized list
const Row = ({ index, style, data }) => {
    const { items, selected, setSelected } = data;
    const item = items[index];

    return (
        <ListItem
            style={style}
            key={item.id}
            disablePadding
            sx={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
            <ListItemButton
                onClick={() => setSelected(item)}
                sx={{
                    width: '100%',
                    backgroundColor: selected.id === item.id ? 'action.selected' : '',
                    '&:hover': {
                        backgroundColor: selected.id === item.id ? 'action.selected' : 'action.hover',
                    },
                }}
            >
                <ListItemText primary={item.name} primaryTypographyProps={{ noWrap: true }} />
            </ListItemButton>
        </ListItem>
    );
};


// List visualizer
const ListVisualizer = ({ imageSet, title, selected, setSelected }) => {
    const theme = useTheme();
    const rowHeight = 50;

    return (
        <Paper elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    width: '100%',
                }}
            >

            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem' ,
                borderRadius: '10px 10px 0 0',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                gap: "1rem",
                backgroundColor: theme.palette.secondary[50],
            }}>

                <ListAltIcon sx={{ color: theme.palette.secondary[800]}} />
                <Typography variant="h4" fontWeight="bold" color={theme.palette.primary[700]}>
                    {title} ({imageSet.length})
                </Typography>

            </Box>

            <Box sx={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '10px',
                }}
            >
                <VirtualizedList
                    height={260}
                    width="100%"
                    itemSize={rowHeight}
                    itemCount={imageSet.length}
                    itemData={{ items: imageSet, selected, setSelected }}
                >
                    {Row}
                </VirtualizedList>
            </Box>
        </Paper>
    )
}


// Custom Slider for the split
const SplitSlider = ({ splitDataset, disabled }) => {
    // State to keep the value of the slider
    const [value, setValue] = useState(80);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
                padding: '1rem',
                width: '100%',
            }}
        >
            {/* Select the % of images for training */}
            <Box sx={{ display: 'flex', flexDirection:'column', width: '100%' }}>
                <Typography id="input-slider" gutterBottom>
                    Split Size
                </Typography>

                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                />
            </Box>

            <CustomButton text="Split" onClick={()=>{splitDataset(value)}} sx={{p:"10px 18px"}} disabled={disabled}/>

        </Box>
    )
}

// Button to move the selected image up or down
const ButtomMove = ({ text, onClick, startIcon, sx, disabled }) => {
    const theme = useTheme();

    const defaultStyles = {
        backgroundColor: theme.palette.primary[600],
        color: 'white',
        padding: '10px 10px',
        fontSize: '1rem',
        borderRadius: '5%',
        width: '8rem',
        textTransform: 'none',
        '&:hover': {backgroundColor: theme.palette.primary[800]},
        ...sx,
    };

    return (
        <Button variant="contained" onClick={onClick} sx={defaultStyles} startIcon={startIcon} disabled={!disabled}>
            {text}
        </Button>
    );
};


const ImageSplitter = ({ trainingSet, setTrainingSet, validationSet, setValidationSet, splitDataset, disabled }) => {
    const [trainingImageSelected, setTrainingImageSelected] = useState({});
    const [validationImageSelected, setValidationImageSelected] = useState({});

    // Callback to handle the selection of an image
    const handleSetTrainingImageSelected = useCallback((image) => {
        setTrainingImageSelected(image);
        setValidationImageSelected({});
    }, []);

    const handleSetValidationImageSelected = useCallback((image) => {
        setValidationImageSelected(image);
        setTrainingImageSelected({});
    }, []);

    // Function to move elements up
    const moveElementUp = () => {
        // If there is no image selected, return
        if (Object.keys(validationImageSelected).length === 0) return;

        // Add the selected image to the training set and remove it from the validation set
        setValidationSet(validationSet.filter((image) => image.id !== validationImageSelected.id));
        setTrainingSet([...trainingSet, validationImageSelected]);

        // Clear the selected image
        setValidationImageSelected({});
        setTrainingImageSelected({});
    }

    // Function to move elements down
    const moveElementDown = () => {
        // If traininigImageSelected is a empty object, return
        if (Object.keys(trainingImageSelected).length === 0) return;

        // Add the selected image to the validation set and remove it from the training set
        setValidationSet([...validationSet, trainingImageSelected]);
        setTrainingSet(trainingSet.filter((image) => image.id !== trainingImageSelected.id));

        // Clear the selected image
        setTrainingImageSelected({});
        setValidationImageSelected({});
    }

    return (
        <Paper
            elevation={0}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '10px',
                background: 'transparent'
            }}
        >
            {/* Training */}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <ListVisualizer
                    title="Training Set"
                    imageSet={trainingSet}
                    selected={trainingImageSelected}
                    setSelected={handleSetTrainingImageSelected}
                />
            </Box>

            {/* Move Up and Down */}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '1rem', gap: '1rem'}}>
                <ButtomMove text="Up" startIcon={<ArrowUpwardIcon />} onClick={moveElementUp} sx={{p:"10px 20px"}} disabled={!disabled}/>
                <ButtomMove text="Down" startIcon={<ArrowDownwardIcon />} onClick={moveElementDown} sx={{p:"10px 20px"}} disabled={!disabled}/>
            </Box>

            {/* Validation */}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListVisualizer
                    title="Validation Set"
                    imageSet={validationSet}
                    selected={validationImageSelected}
                    setSelected={handleSetValidationImageSelected}
                />
            </Box>

            {/* Split Slider */}
            <SplitSlider splitDataset={splitDataset} disabled={disabled} />
        </Paper>
    )

}

export default ImageSplitter;
