import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Paper, Container, useTheme } from '@mui/material';

// Navigation
import { useNavigate } from 'react-router-dom'

import ProjectNameStep from './ProjectNameStep';
import ProjectDescriptionStep from './ProjectDescriptionStep';
import ProjectLabelsStep from './ProjectLabelsStep';
import ProjectCollaboratorsStep from './ProjectCollaboratorsStep';
import WaitingModal from '../Modals/WaitingModal';

// API
import { useSelector } from "react-redux";
import { useCreateProjectMutation } from 'state/api';


const NewProjectForm = () => {
    // Load the theme and the navigation
    const theme = useTheme();
    const navigate = useNavigate();

    // Get the user and the token from the state
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);

    // API call to create a new project and state for the waiting modal
    const [createProject] = useCreateProjectMutation();
    const [isWaiting, setIsWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // Steps
    const steps = ['Project Name', 'Project Description', 'Project Labels', 'Collaborators'];

    // States to store the form information
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectLabels, setProjectLabels] = useState([]);
    const ownerEmail = user.email;
    const creationDate = new Date();
    const [projectCollaborators, setProjectCollaborators] = useState([]);

    // States to check if the step is complete
    const [isStep1Complete, setIsStep1Complete] = useState(false);
    const [isStep2Complete, setIsStep2Complete] = useState(false);
    const [isStep3Complete, setIsStep3Complete] = useState(false);

    // State to control the active step
    const [activeStep, setActiveStep] = useState(0);

    // Function to handle the next and finish
    const handleNext = async () => {
        // Check if we are in the latest step
        if (activeStep === steps.length - 1) {
            // Show the waiting modal
            setIsWaiting(true);

            try {
                // Prepare the request body
                const requestBody = {
                    projectName,
                    projectDescription,
                    projectLabels,
                    ownerEmail,
                    creationDate,
                    projectCollaborators,
                };

                // Make the API call
                const result = await createProject({ token, requestBody }).unwrap();
                setSuccess(true);

            }
            catch (error) {
                setError(true);
                setSuccess(false);
            }
            finally {
                // Hide the waiting modal
                setIsWaiting(false);

            }

            return;
        }

        // Go to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    // When closing the modal, reset the error state as well
    const handleClose = () => {
        // If the user has successfully created the project, navigate to the projects page
        if (success) {
            // Set everything to the initial state
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
            navigate('/dashboard');
        }

        // If there was an error, just close the modal
        else {
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
        }
    };

    const handleBack = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1);};

    // Function to check if the next button should be disabled
    const shouldDisableNextButton = () => {
        switch (activeStep) {
            case 0:
                return !isStep1Complete;
            case 1:
                return !isStep2Complete;
            case 2:
                return !isStep3Complete;
            case 3:
                return false;
            default:
                return false;
        }
    };

    // Return the form
    return (

        // Main Container
        <Container>

            {/* WAITING MODAL */}
            <WaitingModal
                isOpen={isWaiting || success || error}
                message={success ? "Project created successfully" :
                        (error ? "Error" : "Creating project...")}
                success={success}
                error={error}
                onClose={handleClose}
            />

            {/* TOP AREA WITH THE STEPS */}
            <Stepper activeStep={activeStep} alternativeLabel
                sx={{
                    pt: 3,
                    pb: 5,
                    '& .MuiStepIcon-root': {
                        width: 30,
                        height: 30,
                        '& .MuiStepIcon-text': {
                        fontSize: '1.0rem',
                        },
                    },
                    '& .MuiStepLabel-label': {
                        fontSize: '1.0rem',
                    },
                }}
            >
                    {steps.map((label) => (
                        <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
            </Stepper>

            {/* FORM AREA */}
            <Paper elevation={3} sx={{ p: "1.5rem" }}>

                {/* STEP 1. PROJECT TITLE */}
                {activeStep === 0 && (
                    <ProjectNameStep
                        stepProps={{
                            title: "Project Name",
                            subtitle: "Define a name for your project. This will be the title that will be displayed in the project list.",
                            projectName: projectName,
                            setProjectName: setProjectName,
                            isStepComplete: isStep1Complete,
                            setIsStepComplete: setIsStep1Complete,
                        }}
                    />
                )}

                {/* STEP 2. PROJECT DESCRIPTION */}
                {activeStep === 1 && (
                    <ProjectDescriptionStep
                        stepProps={{
                            title: "Project Description",
                            subtitle: "Create a short description for your project. This will help you and your team to understand the purpose of the project.",
                            projectDescription: projectDescription,
                            setProjectDescription: setProjectDescription,
                            isStepComplete: isStep2Complete,
                            setIsStepComplete: setIsStep2Complete,
                        }}
                    />
                )}

                {/* STEP 3. PROJECT LABELS */}
                {activeStep === 2 && (
                    <ProjectLabelsStep
                        stepProps={{
                            title: "Project Labels",
                            subtitle: "Create the labels that you want to use for annotating the images. You can add as many as you want.",
                            projectLabels: projectLabels,
                            setProjectLabels: setProjectLabels,
                            isStepComplete: isStep3Complete,
                            setIsStepComplete: setIsStep3Complete,
                        }}
                    />
                )}

                {/* STEP 4. PROJECT COLLABORATORS */}
                {activeStep === 3 && (
                    <ProjectCollaboratorsStep
                        stepProps={{
                            title: "Project Collaborators",
                            subtitle: "Add the emails of the people that you want to invite to the project. They will be able to see and annotate the images.",
                            userCollaborators: user.collaborators,
                            projectCollaborators: projectCollaborators,
                            setProjectCollaborators: setProjectCollaborators,
                        }}
                    />
                )}

                {/* NAVIGATION BUTTONS */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: "2rem", gap:"1rem"}}>

                    {/* BACK BUTTON */}
                    <Button
                        variant='outlined'
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{
                            mr: 1,
                            textDecoration: 'none',
                            padding: '10px 30px',
                            fontSize: '1rem',
                            borderRadius: '2%',
                            textTransform: 'none',
                        }}
                    >
                        Back
                    </Button>

                    {/* NEXT BUTTON */}
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={shouldDisableNextButton()}
                        sx={{
                            backgroundColor: theme.palette.primary[800],
                            textDecoration: 'none',
                            color: 'white',
                            padding: '10px 30px',
                            fontSize: '1rem',
                            borderRadius: '2%',
                            textTransform: 'none',
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: theme.palette.primary[900],
                            },
                        }}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>

                </Box>
            </Paper>
        </Container>
    );
};

export default NewProjectForm;
