import { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

const ProjectDescriptionStep = ({ stepProps }) => {
    // Get the theme
    const theme = useTheme();

    // Get the props
    const { projectDescription, setProjectDescription, isStepComplete, setIsStepComplete, title, subtitle } = stepProps;

    // Function to handle changes in the project description
    const handleProjectDescriptionChange = (event) => {
        setProjectDescription(event.target.value);
        setIsStepComplete(event.target.value !== '');
    };

    // Return the component
    return (

        // Main Box Area
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center' }}>

            {/* Title and subtitle */}
            <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{subtitle}</Typography>
            </Box>

            <TextField
                label="Project Description"
                multiline
                value={projectDescription}
                rows={8}
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder="Describe your project here..."
                onChange={(e) => handleProjectDescriptionChange(e)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.primary[300],
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary[600],
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.dark',
                        },
                    },
                }}
            />
        </Box>
    );
}

export default ProjectDescriptionStep;
