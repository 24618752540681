import React, { useState, useEffect } from 'react';
import { Paper, Box, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import ItemSelector from 'components/General/ItemSelector';

const ProgressConfiguration = ({ startDate, endDate, setStartDate, setEndDate, stadistics, labels, handleSelectedLabel }) => {
    const theme = useTheme();
    const [minDate, setMinDate] = useState(dayjs());
    const [maxDate, setMaxDate] = useState(dayjs());
    const [images, setImages] = useState(0);

    useEffect(() => {
        // If stadistics is not defined or empty, return
        if (!stadistics || stadistics.length === 0) {
            setMinDate(dayjs());
            setMaxDate(dayjs());
            return;
        }

        // Find the minimum date from stadistics
        const minAnnotationDate = stadistics.reduce((min, current) => {
            const currentDate = dayjs(current.createdAt); // Convert to dayjs object
            return currentDate.isBefore(min) ? currentDate : min;
        }, dayjs());

        setMinDate(minAnnotationDate);
        setStartDate(minAnnotationDate);
        setMaxDate(dayjs());
        setEndDate(dayjs());

        // Find the number of different imageId
        const imagesList = stadistics.map((annotation) => annotation.imageId);
        const uniqueImages = new Set(imagesList);
        setImages(uniqueImages.size);

    }, [stadistics]); // eslint-disable-line

    return (
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent:'space-around', alignItems: 'center', padding: '1rem' }}>

            <Box width='40%' display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {stadistics.length === 0 ? 0 : stadistics.length}
                </Typography>
                <Typography variant="h6" color={theme.palette.secondary[500]}>
                    Annotations
                </Typography>
            </Box>

            <Box width='40%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" mr="2rem">
                <Typography variant="h3" fontWeight='bold' color={theme.palette.primary[600]}>
                    {images === 0 ? 0 : images}
                </Typography>
                <Typography variant="h6" color={theme.palette.secondary[500]}>
                    Images Annotated
                </Typography>
            </Box>

            <Box width='100%' display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="1rem">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                            if (newValue.isAfter(endDate)) {
                                setEndDate(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={minDate}
                        maxDate={endDate}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                            if (newValue.isBefore(startDate)) {
                                setStartDate(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={startDate}
                        maxDate={maxDate}
                    />
                </LocalizationProvider>
            </Box>

            <ItemSelector items={labels} selectionChanged={handleSelectedLabel} label="Labels" setFirstElement={true} />

        </Paper>
    );
};

export default ProgressConfiguration;
