import { useState, useEffect, useCallback } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useDialog } from 'components/General/CustomDialog';
import { useGetModelListMutation, useChangeModelStateMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to fetch and update the image information
function useFetchModelList(projectId) {

    // Variables
    const token = useSelector(selectToken);
    const [models, setModels] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { requestConfirm } = useDialog();

    const [getModelList] = useGetModelListMutation();
    const [changeModelState] = useChangeModelStateMutation();


    // If the projectId changes, fetch the new list of models
    useEffect(() => {
        // Early return if projectId is not available
        if (!projectId) return;

        const fetchData = async () => {
            try{
                const response = await getModelList({ token, requestBody: { projectId: projectId } }).unwrap();
                if(response.success){
                    setModels(response.models);
                } else {
                    // console.error('Failed to fetch the list of models:', response.message);
                    setModels([]);
                }
            } catch (error) {
                console.error('Failed to fetch the list of models:', error);
                setModels([]);
            }
        };
        fetchData();

    }, [projectId, refreshCounter]); // eslint-disable-line react-hooks/exhaustive-deps


    // Function to update the model state
    const updateModelState = useCallback(async (model) => {

        // Check if the model is active
        if (model.active) {
            // Confirm if the user want to deactivate the model
            const confirmResult = await requestConfirm("Are you sure you want to deactivate the model?");
            if (confirmResult) {
                // Prepare the requestBody
                const requestBody = { modelId: model.id, active: false, projectId: projectId };
                try {
                    const result = await changeModelState({ token, requestBody }).unwrap();
                    if(result.success){
                        setRefreshCounter(prevCounter => prevCounter + 1);
                    }
                } catch (error) {
                    console.error("Failed to update the active project:", error);
                }
            };
        } else {
            // Check if the model is completed
            if(model.isCompleted === false){
                await requestConfirm("The model is not completed yet");
                return;
            } else {
                const confirmResult = await requestConfirm("Are you sure you want to activate the model?");
                if (confirmResult) {
                    // Prepare the requestBody
                    const requestBody = { modelId: model.id, active: true, projectId: projectId };
                    try {
                        const result = await changeModelState({ token, requestBody }).unwrap();
                        if(result.success){
                            setRefreshCounter(prevCounter => prevCounter + 1);
                        }
                    } catch (error) {
                        console.error("Failed to update the active project:", error);
                    }
                };
            }
        }

    }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    return { models, updateModelState };
}

export default useFetchModelList;