import React, { useRef, useState, useEffect } from 'react';
import { Box, BottomNavigationAction, BottomNavigation, Paper, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';


import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import CustomButton from 'components/General/CustomButton';

import ImageEditor from 'components/ImageEditor/ImageEditor';
import ImageAnnotationInfo from 'components/ImageEditor/ImageAnnotationInfo';
import ProjectSelector from 'components/General/ProjectSelector';
import ProjectImagesList from "components/Lists/ProjectImagesList";
import ProgressModal from "components/Forms/Modals/ProgressModal";

// API to fetch the model
import { useSelector } from "react-redux";
import { useGetModelMutation } from 'state/api';
import * as tf from '@tensorflow/tfjs';

// Custom Hooks
import useFetchImagesList from "components/Hooks/useFetchImagesList";
import useRefreshImagesToken from "components/Hooks/useRefreshImagesToken";
import useAnnotationsInformation from "components/Hooks/useAnnotationsInformation";

const Annotate = () => {
    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));
    const token = useSelector((state) => state.persistedReducer.token);

    const [project, setProject] = useState(null);
    const [image, setImage] = useState(null);
    const [refreshImagesList, setRefreshImagesList] = useState(false);
    const {data: imagesList} = useFetchImagesList(project?.id, refreshImagesList);
    const {loading: tokensRefreshing, progress: tokensProgress, refreshTokens} = useRefreshImagesToken(project?.id, imagesList);
    const { annotations, setAnnotations, forceUpdate, forceClear } = useAnnotationsInformation(project?.id, image?.id, () => { setRefreshImagesList(!refreshImagesList) });
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);

    const [modelLoaded, setModelLoaded] = useState(null);
    const [getModel, { data: modelData, isLoading, isError }] = useGetModelMutation();
    const [modelReady, setModelReady] = useState(0);

    // Refresh the tokens of the images, if needed
    useEffect(() => {
        if (imagesList) {
            // Refresh the tokens if the time to expire is less than 120 minutes
            if (imagesList.length > 0) {
                // Check if the field accessTokenExpireDate is empty for any of the images (if so, refresh the tokens)
                if (imagesList.some(image => image.accessTokenExpireDate === '')) {
                    refreshTokens();
                }

                else{
                    // Get the index of the oldest image token date (the closest to expire)
                    const oldestTokenIndex = imagesList.reduce((oldestIndex, image, currentIndex) => {
                        if (image.accessTokenExpireDate < imagesList[oldestIndex].accessTokenExpireDate) {
                            return currentIndex;
                        } else {
                            return oldestIndex;
                        }
                    }, 0);

                    // Compute the difference between the token date and the current date in minutes
                    const tokenDate = new Date(imagesList[oldestTokenIndex].accessTokenExpireDate);
                    const currentDate = new Date();

                    // Check the diference
                    const timeToExpire = Math.round((tokenDate - currentDate) / 60000);
                    if (timeToExpire < 120) {
                        refreshTokens();
                    }
                }
            }
        }
    }, [imagesList]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleNewProjectSelected = (project) => {
        setProject(project);
        forceClear();
        setRefreshImagesList(!refreshImagesList);
        setImage(null);
        setSelectedAnnotation(null);
    }

    const handleNewImageSelected = (selectedImage) => {

        // If the selectedImage is null (an image was deleted), reset the current imageList
        if (selectedImage === null) {
            // Reset the current image
            forceClear();
            setRefreshImagesList(!refreshImagesList)
            setImage(null);
            setSelectedAnnotation(null);
        }

        // If the selectedImage is equal to the image, force an update
        else if (selectedImage.id === image?.id) {
            forceUpdate();
        }

        else {
            // Set the current image
            setImage(selectedImage);
            setSelectedAnnotation(null);
        }
    }


    // Helper function to fetch and concatenate binary weight files
    async function fetchAndConcatenateWeightFiles(signedShardURLs) {

        const buffers = await Promise.all(signedShardURLs.map(async (url) => {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            return buffer;
        }));

        // Concatenate all buffers
        let totalLength = buffers.reduce((acc, val) => acc + val.byteLength, 0);
        let combined = new Uint8Array(totalLength);
        let offset = 0;
        buffers.forEach(buffer => {
            combined.set(new Uint8Array(buffer), offset);
            offset += buffer.byteLength;
        });

        return combined.buffer; // Return as ArrayBuffer
    }

    // Function to load the model from S3
    async function loadModelFromS3(signedModelJsonURL, signedShardURLs) {
        // Step 1. Fetch the model.json file
        const modelJsonResponse = await fetch(signedModelJsonURL);
        const modelJson = await modelJsonResponse.json();

        // Fetch and concatenate weight files
        const weightData = await fetchAndConcatenateWeightFiles(signedShardURLs);

        // Prepare weight specs from the modelJson (assuming it's already included in your modelJson)
        const weightSpecs = modelJson.weightsManifest.flatMap(wm => wm.weights);

        // Create a ModelArtifacts object
        const modelArtifacts = {
            modelTopology: modelJson.modelTopology,
            weightSpecs: weightSpecs,
            weightData: weightData
        };

        // Step 4. Load the model
        try {
            const model = await tf.loadGraphModel(tf.io.fromMemory(modelArtifacts));
            return model;
        } catch (error) {
            console.error("Error loading model from S3", error);
            throw error; // Rethrow or handle as needed
        }
    }

    // Get the model from the server every time the projectID changes
    useEffect(() => {
        if (project?.id) {
            // Prepare the requestBody
            const requestBody = { projectId: project.id };

            // Get the model from the server
            getModel({ token, requestBody });

        }
    }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

    // Update the model state when the data is fetched
    useEffect(() => {
        // Define an async function inside the useEffect
        async function fetchDataAndLoadModel() {
            if (modelData?.model) {
                const signedModelJsonUrl = modelData.model.modelJsonUrl;
                const signedShardUrls = modelData.model.shardUrls; // Ensure this is structured correctly for your use case
                setModelReady(2);

                try {
                    const model = await loadModelFromS3(signedModelJsonUrl, signedShardUrls);
                    setModelLoaded(model);
                    setModelReady(1);

                } catch (error) {
                    setModelLoaded(null);
                    setModelReady(-1);
                    console.error("Failed to load model", error);
                }
            } else {
                setModelLoaded(null);
                setModelReady(-1);
            }
        }
        // Call the async function
        fetchDataAndLoadModel();
    }, [modelData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Function to update the selected annotation
    const handleAnnotationSelected = (annotationId) => {
        setSelectedAnnotation(annotationId);
    }

    return (
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>
            {/* 0. Modals */}
            <ProgressModal progress={tokensProgress} total={imagesList?.length} isOpen={tokensRefreshing} onClose={() => {setRefreshImagesList(!refreshImagesList)}}/>

            {/* 1. Header */}
            <FlexBetween>
                <Header title="Annotate Images" subtitle="Annotate your images here" />
                <ProjectSelector handleNewProjectSelected={handleNewProjectSelected} />
            </FlexBetween>

            {/* 2. Main Content */}
            <Box display="flex" flexGrow={10} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* List of Images */}
                    <Box gridColumn="span 3" gridRow="span 4" color={theme.palette.secondary[1000]}>
                        <ProjectImagesList
                            project={project}
                            imagesList={imagesList}
                            newImageSelected={handleNewImageSelected}
                        />
                    </Box>

                    {/* Image */}
                    <Box gridColumn="span 9" gridRow="span 8" display="flex" flexDirection="column" color={theme.palette.secondary[1000]}>
                        <ImageEditor
                            key={image ? image.id : ''}
                            accessToken={image ? image.accessToken : ''}
                            annotations={annotations ? annotations : []}
                            setAnnotations={setAnnotations}
                            labels={project? project.labels : []}
                            inputModel={modelLoaded}
                            modelReady={modelReady}
                            annotationSelected={handleAnnotationSelected}
                        />
                    </Box>

                    {/* Notes and Tags */}
                    <Box gridColumn="span 3" gridRow="span 4" display="flex" flexDirection="column" color={theme.palette.secondary[1000]}>
                        <ImageAnnotationInfo
                            key={image ? image.id : ''}
                            annotations={annotations ? annotations : []}
                            setAnnotations={setAnnotations}
                            selectedAnnotation={selectedAnnotation ? selectedAnnotation : null}
                        />
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default Annotate;
