import { useState, useEffect } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useGetImagesListMutation } from "state/api";

// Token selector
const selectToken = state => state.persistedReducer.token;

// Custom hook to fetch the list of images availables in a project
function useFetchImagesList(projectId, refresh) {

    // Variables
    const token = useSelector(selectToken);
    const [getImagesList] = useGetImagesListMutation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    // Fetch the image list when the projectId is available (or changed)
    useEffect(() => {
        // Early return if projectId is not available
        if (!projectId) return;

        // Function to fetch the images list
        const fetchData = async () => {
            // Set the loading state
            setLoading(true);

            // If projectId is available and not ''
            try {
                const response = await getImagesList({ token, requestBody: { projectId: projectId } }).unwrap();
                if (response.success) {
                    setData(response.data);
                } else {
                    setError(response.error);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [projectId, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    return { data, loading, error };
}

export default useFetchImagesList;