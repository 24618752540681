import React, { useRef, useState, useEffect } from 'react';
import { Box, BottomNavigationAction, BottomNavigation, Paper, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';


import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import ProjectSelector from 'components/General/ProjectSelector';
import ImageSplitter from 'components/Lists/ImageSplitter';
import SplitInformation from 'components/Graphs/SplitInformation';

import WaitingModal from 'components/Forms/Modals/WaitingModal';



// Custom Hooks
import usePrepareTrainingData from "components/Hooks/usePrepareTrainingData";

const TrainModel = () => {

    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));
    const [project, setProject] = useState(null);
    const [refreshImagesList, setRefreshImagesList] = useState(false);
    const { fullImageList, trainList, validationList, progressInformation, setProgressInformation,
            setTrainList, setValidationList, splitDataset, submitTrainingRequest } = usePrepareTrainingData(project?.id, refreshImagesList)

    // Fetch the images list
    const handleNewProjectSelected = (project) => {
        setProject(project);
        setRefreshImagesList(!refreshImagesList);
    }

    return(
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 0. Modals */}
            <WaitingModal
                isOpen={progressInformation.isWaiting || progressInformation.success || progressInformation.error}
                message={progressInformation.message}
                success={progressInformation.success}
                error={progressInformation.error}
                onClose={() => { setProgressInformation({ isWaiting: false, success: false, error: false, message: '' }); }}
            />

            {/* 1. Header */}
            <FlexBetween>
                    <Header title="Train a model" subtitle="Request the training of your custom model" />
                    <ProjectSelector handleNewProjectSelected={handleNewProjectSelected} />
            </FlexBetween>

            {/* 2. Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.1. Image Splitter */}
                    <Box gridColumn="span 3" gridRow="span 8" color={theme.palette.secondary[1000]}>
                        <ImageSplitter
                            trainingSet={trainList ? trainList : []}
                            validationSet={validationList ? validationList : []}
                            setTrainingSet={setTrainList}
                            setValidationSet={setValidationList}
                            splitDataset={splitDataset}
                            disabled={fullImageList ? fullImageList.length === 0 : true}
                        />
                    </Box>

                    {/* Training Request Configuration */}
                    <Box gridColumn="span 9" gridRow="span 8" color={theme.palette.secondary[1000]}>
                        <SplitInformation
                            labels={project?.labels}
                            trainingSet={trainList}
                            validationSet={validationList}
                            submitTrainingRequest={submitTrainingRequest}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TrainModel;