import React, { useState, useEffect } from 'react';
import { Paper, Box, Button, Skeleton, useTheme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import PublishIcon from '@mui/icons-material/Publish';

// Button to move the selected image up or down
const ButtomSubmit = ({ text, onClick, sx, disabled=false }) => {
    const theme = useTheme();

    const defaultStyles = {
        backgroundColor: theme.palette.primary[600],
        color: 'white',
        padding: '10px 10px',
        fontSize: '1rem',
        borderRadius: '5%',
        width: '16rem',
        textTransform: 'none',
        '&:hover': {backgroundColor: theme.palette.primary[800]},
        ...sx,
    };

    return (
        <Button variant="contained" onClick={onClick} sx={defaultStyles} startIcon={<PublishIcon />} disabled={disabled}>
            {text}
        </Button>
    );
};


// Function to process the data
const processData = (trainingSet, validationSet, labels) => {
    // Initialize counts with label names and set training and validation counts to 0
    const counts = labels.reduce((acc, label) => {
        acc[label.text] = { name: label.text, training: 0, validation: 0 };
        return acc;
    }, {});

    // Function to count annotations in a dataset
    const countAnnotations = (data, datasetType) => {
        data.forEach(item => {
            item.annotationsAvailable.forEach(annotation => {
                if (counts[annotation.label]) {
                    counts[annotation.label][datasetType] += annotation.count;
                }
            });
        });
    };

    // Count annotations in both datasets
    countAnnotations(trainingSet, 'training');
    countAnnotations(validationSet, 'validation');

    return Object.values(counts);
};


const SplitInformation = ({ labels, trainingSet, validationSet, submitTrainingRequest }) => {

    // Get the theme and process the data
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [chartType, setChartType] = useState('bar');
    const [chartKey, setChartKey] = useState(Date.now());

    // Process the data if it is available
    useEffect(() => {
        // If they are not undefined, process the data
        if (trainingSet && validationSet && labels) {
            const newData = processData(trainingSet, validationSet, labels);
            setData(newData);
            setTotalData(newData.map(d => ({ name: d.name, value: d.training + d.validation })));
            setChartKey(Date.now());
        }
    }, [trainingSet, validationSet, labels]); // eslint-disable-line react-hooks/exhaustive-deps

    // Toggle chart type
    const handleChartTypeChange = (event, newChartType) => {
        if (newChartType !== null) {
            setChartType(newChartType);
        }
    };

    // If data is empty, return skeleton
    if (!data.length) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    // Return the chart
    return(
        <Paper elevation={3} sx={{height:"100%",display:"flex", flexDirection:"column", p:'1rem 1rem 1.5rem 1rem'}}>
            <React.Fragment>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", p:'0rem 1rem 1rem 1rem'}} >

                    <ButtomSubmit text={"Submit Training Request"} onClick={submitTrainingRequest} disabled={trainingSet.length === 0} />

                    <ToggleButtonGroup
                        color="primary"
                        value={chartType}
                        exclusive
                        onChange={handleChartTypeChange}
                        sx={{ mb: 2, alignSelf: 'flex-end' }}
                    >
                        <ToggleButton value="bar">Bar Chart</ToggleButton>
                        <ToggleButton value="pie">Pie Chart</ToggleButton>
                    </ToggleButtonGroup>

                </Box>

                <ResponsiveContainer width="100%">
                    {chartType === 'bar' ? (
                        <BarChart
                            key={chartKey}
                            data={data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="training" fill={theme.palette.primary[800]} name="Training Set" />
                            <Bar dataKey="validation" fill="#f25634" name="Validation Set" />
                        </BarChart>
                    ) : (
                        <PieChart>
                            <Pie
                                key={chartKey}
                                data={totalData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                fill={theme.palette.primary.main}
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {totalData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={labels.find(l => l.text === entry.name)?.color || '#8884d8'} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="horizontal" align="center" verticalAlign="bottom"/>
                        </PieChart>
                    )}
                </ResponsiveContainer>
            </React.Fragment>
        </Paper>
    );
}

export default SplitInformation;