import { createSlice } from '@reduxjs/toolkit';

// State that will be shared across the app
const initialState = {
    user: null,
    token: null,
};

// Slice for the global state
export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {

        // Set the user id and token when the user logs in
        setLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },

        // Clear the user id and token when the user logs out
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },

        // Refresh the access token and the expiration time for the user
        setRefreshToken: (state, action) => {
            state.user.profilePictureAccessToken = action.payload.accessToken;
            state.user.profilePictureExpireDate = action.payload.expirationTime;
        },
    }
});

export const { setLogin, setLogout, setRefreshToken } = globalSlice.actions;
export default globalSlice.reducer;