// WaitingModal.js
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Success icon
import CancelIcon from '@mui/icons-material/Cancel'; // For the red cross icon
import Button from '@mui/material/Button';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400, // Increased width
    height: 400, // Increased height, if you want a specific height
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};

const WaitingModal = ({ isOpen, message, success, error, onClose }) => {
    return (
      <Modal
        open={isOpen}
        aria-labelledby="waiting-modal-title"
        aria-describedby="waiting-modal-description"
      >
        <Box sx={modalStyle}>
          {success ? (
            <>
              <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
              <p id="waiting-modal-description" style={{ marginTop: '20px', fontSize: '1.5rem', textAlign: 'center' }}>{message}</p>
            </>
          ) : error ? (
            <>
              <CancelIcon style={{ fontSize: 80, color: 'red' }} />
              <p id="waiting-modal-description" style={{ marginTop: '20px', fontSize: '1.5rem', textAlign: 'center' }}>{message}</p>
            </>
          ) : (
            <>
              <CircularProgress size={80} />
              {message && <p id="waiting-modal-description" style={{ marginTop: '20px', fontSize: '1.5rem', textAlign: 'center' }}>{message}</p>}
            </>
          )}
          {/* Conditionally render the Close button only if not waiting */}
          {(success || error) && (
            <Button
              variant="contained"
              onClick={onClose}
              style={{ marginTop: '20px', padding: '10px 20px', fontSize: '1.1rem' }}
              size="large"
            >
              Close
            </Button>
          )}
        </Box>
      </Modal>
    );
  };

export default WaitingModal;
