import { useState, useEffect } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useGetUserStadisticsMutation } from "state/api";

// Token selector
const selectUser = state => state.persistedReducer.user;
const selectToken = state => state.persistedReducer.token;

// Custom hook to fetch the list of images availables in a project
function useFetchUserStadistics() {

    // Variables
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const [getUserStadistics] = useGetUserStadisticsMutation();
    const [data, setData] = useState([]);

    // Fetch the image list when the projectId is available (or changed)
    useEffect(() => {
        // Early return if projectId is not available
        if (!user) return;

        // Function to fetch the images list
        const fetchData = async () => {

            // If projectId is available and not ''
            try {
                const response = await getUserStadistics({ token, requestBody: { userEmail: user.email } }).unwrap();
                if (response.success) {
                    setData(response.data);
                } else {
                    setData([]);
                }
            } catch (error) {
                setData([]);
            }
        };

        fetchData();
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    return { data };
}

export default useFetchUserStadistics;