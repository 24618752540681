// Material UI imports
import { Typography, Box, useTheme } from "@mui/material";

// React imports
import React from "react";

// Header component
const Header = ({ title, subtitle }) => {
    // Use theme
    const theme = useTheme();

    // Return the component
    return (
        <Box>
            {/* Title */}
            <Typography variant="h2" color={theme.palette.primary[900]} fontWeight="bold" sx={{ mb: "5px" }}>
                {title}
            </Typography>

            {/* Subtitle */}
            <Typography variant="h5" color={theme.palette.primary[700]}>
                {subtitle}
            </Typography>
        </Box>
    );
};

export default Header;