import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';


import Header from 'components/General/Header';
import FlexBetween from 'components/General/FlexBetween';
import ProjectSelector from 'components/General/ProjectSelector';


const Details = () => {

    // Check the theme and the screen size
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));


    // Render the component
    return(
        <Box m="1.5rem 2.5rem" display='flex' flexDirection='column' height='90vh'>

            {/* 1. Header */}
            <FlexBetween>
                    <Header title="Details" subtitle="Details about the annotations of your project" />
            </FlexBetween>

            {/* 2. Content */}
            <Box display="flex" flexGrow={1} width='100%'>
                <Box
                    mt="20px"
                    width='100%'
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="repeat(8, 1fr)"
                    gap="20px"
                    sx={{"& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12" }}}
                >

                    {/* 2.2 Graph */}
                    <Box gridColumn="span 12" gridRow="span 6" color={theme.palette.secondary[1000]}>
                        <Box height='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">
                            <Typography variant="h2" color={theme.palette.secondary[600]} fontWeight="bold">
                                This functionality is not available yet
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default Details;